// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageBusinessPartner() {

    const mvp_auto = "images/partnerLogo/mvp_auto_1.jpeg";
    const kaj_auto = "images/partnerLogo/kaj_auto.jpg";
    const victor_auto = "images/partnerLogo/victor_auto.jpg";
    const vsp_auto = "images/partnerLogo/vsp_auto.jpg";
    const mines_auto = "images/partnerLogo/mines_auto.jpeg";
    const cs_smart = "images/partnerLogo/cs_smart.jpeg"

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Business Partner — ${theme.name}`}</title>
            </Helmet>

            <PageHeader />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Our Business Partner</h1>
                        </div>
                        <div className="document__content typography">
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <div className="d-flex justify-content-center" style={{ height: "150px" }}>
                                        <img
                                            src={mines_auto}
                                            className="img-fluid avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                            alt="mvp_auto"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p align="center">
                                        <div><strong>MINES AUTO TRADING SDN BHD</strong></div>
                                        <div>LEVEL 1 , TAMAN EQUINE , JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="d-flex justify-content-center" style={{ height: "150px" }}>
                                        <img
                                            src={mvp_auto}
                                            className="img-fluid avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                            alt="mvp_auto"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p align="center">
                                        <div><strong>MVP AUTO SDN BHD</strong></div>
                                        <div>LEVEL 3 , TAMAN EQUINE , JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="d-flex justify-content-center" style={{ height: "150px" }}>
                                        <img
                                            src={kaj_auto}
                                            className="img-fluid avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                            alt="mvp_auto"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p align="center">
                                        <div><strong>KAJ AUTO SDN BHD</strong></div>
                                        <div>LEVEL 4 , TAMAN EQUINE , JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="d-flex justify-content-center" style={{ height: "150px" }}>
                                        <img
                                            src={victor_auto}
                                            className="img-fluid avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                            alt="mvp_auto"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p align="center">
                                        <div><strong>VICTOR AUTO SDN BHD</strong></div>
                                        <div>LEVEL 3 , TAMAN EQUINE , JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <p align="center">
                                        <div><strong>EQUINE AUTO CITY SDN BHD</strong></div>
                                        <div>ROOFTOP , TAMAN EQUINE , JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="d-flex justify-content-center" style={{ height: "150px" }}>
                                        <img
                                            src={cs_smart}
                                            className="img-fluid avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                            alt="mvp_auto"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p align="center">
                                        <div><strong>CS SMART AUTOMOTIVE SDN BHD</strong></div>
                                        <div>LOT 9 GROUND FLOOR , TAMAN EQUINE, JALAN EQUINE 10E ,</div>
                                        <div>MULTI-LEVEL CARPARK, 43300 SERI KEMBANGAN</div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageBusinessPartner;
