// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";

import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
import cleanFormData from '../../helper/general-helper';
import * as USER from '../../api/user';
import { toast } from 'react-toastify';

function SitePageResendVerification() {
    const { register, errors, handleSubmit, setError, reset } = useForm(); /* register is a function */
    const [apiLoading, setApiLoading] = useState(false);

    const onSubmit = (data) => {
        setApiLoading(true);
        let formData = {
            ...data,
            portal_type: process.env.REACT_APP_PORTAL_TYPE,
        }
        cleanFormData(formData);

        USER.resendVerificationLink(formData)
            .then(res => {
                toast.success("Successfully resend the verification link.Kindly check your email inbox.", { autoClose: 3000 })
                reset({ email: "" })
                setApiLoading(false);
            })
            .catch(err => {
                setApiLoading(false);
                if (err.http_code == 422 && err.data.errors) {
                    addServerErrors(err.data.errors);
                }
                else if (err.data.message) {
                    toast.error(err.data.message, { autoClose: 3000 });
                } else {
                    toast.error("Something went wrong.Please try again later.", { autoClose: 3000 })
                }
            })
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message
                });
            });
        }
    };

    return (
        <div className="block container">
            <Helmet>
                <title>{`Resend Verification — ${theme.name}`}</title>
            </Helmet>
            <div className="row justify-content-center">
                <div className="mt-5 col-12 col-md-10">
                    <div className="card flex-grow-1 p-4">
                        <div className="card-body">
                            <h1 className="not-found__title">Resend Verification link</h1>
                            <p className="not-found__text">
                                Please enter your email address.
                            <br />
                                We will send you the email verification link again via email.
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input
                                                className={`form-control ${errors.email == null ? '' : 'is-invalid'}`}
                                                id="email" name="email" type="email" placeholder="Enter Email"
                                                ref={register({
                                                    required: { value: true, message: "Email is required" },
                                                    maxLength: { value: 255, message: "Email should not exceed 255 characters" },
                                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                                                })}
                                            />
                                            {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                    </div>
                                </div>
                                {apiLoading ? (
                                    <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                        Send
                                    </button>)}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageResendVerification;
