import * as HELPER from "../helper/general-helper";
import authHeader from "../helper/auth-header";
const axios = require("axios");

export async function registerMember(data) {
  // let header = await authHeader();
  // header["Content-Type"]= "multipart/form-data"
  // header["Access-Control-Allow-Origin"]= "*"

  var formData = HELPER.appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_HOST + "/"
        + process.env.REACT_APP_API_PREFIX +
        "/register", formData,
        {
          timeout: 60000,
          //   headers: header
        })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function forgotMyPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/forgot-password",
        formData,
        {
          timeout: 60000,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getProfile() {
  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/my-profile";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: authHeader(),
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function updateMyProfile(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
        "/" +
        process.env.REACT_APP_API_PREFIX +
        "/my-profile?_method=PUT",
        formData,
        {
          timeout: 60000,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function changeMyPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_HOST +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/my-profile/change-password?_method=PUT",
        formData,
        {
          timeout: 60000,
          headers: header,
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getEnquiry(params) {
  let querystring = HELPER.paramBuilder(params);
  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/my-enquiry" + querystring;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: authHeader(),
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function resendVerificationLink(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
      axios
          .post(
              process.env.REACT_APP_HOST +
              "/" +
              process.env.REACT_APP_API_PREFIX +
              "/email/resend",
              formData,
              {
                  timeout: 60000,
                  headers: header,
              }
          )
          .then((response) => {
              const { data } = response;
              resolve(data);
          })
          .catch((error) => {
              if (error.response) {
                  if (error.response.data) {
                      reject({
                          http_code: error.response.status,
                          data: error.response.data,
                      });
                  } else {
                      reject(error.response.data);
                  }
              } else {
                  reject(error.message);
              }
          })
          .finally();
  });
}

export async function resetPassword(updateData) {
  let header = await authHeader();
  header["Content-Type"] = "multipart/form-data";
  header["Access-Control-Allow-Origin"] = "*";

  var formData = HELPER.appendFormData(updateData);

  return new Promise((resolve, reject) => {
      axios
          .post(
              process.env.REACT_APP_HOST +
              "/" +
              process.env.REACT_APP_API_PREFIX +
              "/reset-password",
              formData,
              {
                  timeout: 60000,
                  headers: header,
              }
          )
          .then((response) => {
              const { data } = response;
              resolve(data);
          })
          .catch((error) => {
              if (error.response) {
                  if (error.response.data) {
                      reject({
                          http_code: error.response.status,
                          data: error.response.data,
                      });
                  } else {
                      reject(error.response.data);
                  }
              } else {
                  reject(error.message);
              }
          })
          .finally();
  });
}