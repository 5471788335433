// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
// import departments from '../../data/headerDepartments';

import * as GENERAL from '../../api/general'

function DepartmentsLinks() {

    const [departments, setDepartments] = useState([]);
    const carBrandImgList = [
        { id: 0, name: "ALL BRAND", image: "", slug: "all-brand" }
    ];
    useEffect(() => {
        let params = {
            per_page: 'unlimited',
            is_popular: 1,
            sort_by: 'name',
            sort_type: 'asc'
        }
        GENERAL.getPopularBrandList(params)
            .then(res => {
                let list = res.data;
                list.data = [...list.data, ...carBrandImgList];
                setDepartments(list.data)
                localStorage.setItem("popular_brand", JSON.stringify(list.data))
            })
            .catch(err => {

            })
    }, []);

    // const departments = [];
    const linksList = departments.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        if (department.submenu) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (department.submenu && department.submenu.type === 'menu') {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <Menu items={department.submenu.menu} />
                </div>
            );
        }

        if (department.submenu && department.submenu.type === 'megamenu') {
            submenu = (
                <div className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}>
                    <Megamenu menu={department.submenu.menu} location="department" />
                </div>
            );
        }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={department.name == 'ALL BRAND' ? '/all-brand' : `/brand/${department.slug}`}>
                    {department.name}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
        </ul>
    );
}

export default DepartmentsLinks;
