// react
import React, { useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
// import MobileLinks from './MobileLinks';
// import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';
import * as GENERAL from "../../api/general"

import { Link } from 'react-router-dom';

function MobileMenu(props) {
  const {
    mobileMenuState,
    closeMobileMenu,
    changeLocale
    // popularBrand,
  } = props;
  const [popularBrand, setPopularBrand] = useState([]);
  const [currentActive, setCurrentActive] = useState("en");

  const classes = classNames('mobilemenu', {
    'mobilemenu--open': mobileMenuState.open,
  });

  const carBrandImgList = [
    { id: 0, name: "ALL BRAND", image: "", slug: "all-brand" }
  ];

  useEffect(() => {
    let params = {
      per_page: 'unlimited',
      is_popular: 1,
      sort_by: 'name',
      sort_type: 'asc'
    }
    GENERAL.getPopularBrandList(params)
      .then(res => {
        let list = res.data;
        list.data = [...list.data, ...carBrandImgList];
        setPopularBrand(list.data)
      })
      .catch(err => {

      })
  }, [])

  // const handleItemClick = (item) => {
  //     if (item.data) {
  //         if (item.data.type === 'language') {
  //             changeLocale(item.data.locale);
  //             closeMobileMenu();
  //         }
  //         // if (item.data.type === 'currency') {
  //         //     const currency = currencies.find((x) => x.currency.code === item.data.code);

  //         //     if (currency) {
  //         //         changeCurrency(currency.currency);
  //         //         closeMobileMenu();
  //         //     }
  //         // }
  //     }
  //     if (item.type === 'link') {
  //         closeMobileMenu();
  //     }
  // };

  const carBrand = (
    <div className="section">
      <div className="section-title">Shop By Brand</div>
      {popularBrand.map((item, index) => {
        return (
          <li className="category-item" key={item.id}>
            <Link to={item.name == 'ALL BRAND' ? '/all-brand' :`/brand/${item.slug}`} onClick={closeMobileMenu}>
              {item.name}
            </Link>
          </li>
        );
      })}
    </div>
  );

  return (
    <div className={classes}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
      <div className="mobilemenu__body">
        <div className="mobilemenu__content">
          {carBrand}
          {/* <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} /> */}
        </div>
        <div className="mobilemenu__content" style={{ position: 'relative' }}>
          <div className="row" style={{ position: 'absolute', bottom: 0, padding: 'inherit' }} onClick={closeMobileMenu}>
            <div onClick={() => { changeLocale('en'); setCurrentActive('en') }}><span className={currentActive == 'en' ? 'language-text-active' : ''}>ENGLISH</span></div>
            <div className="pl-2 pr-2"> | </div>
            <div onClick={() => { changeLocale('malay'); setCurrentActive('malay') }}><span className={currentActive == 'malay' ? 'language-text-active' : ''}>MALAY</span></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
  closeMobileMenu: mobileMenuClose,
  changeLocale: localeChange,
  changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
