// react
import React, { useEffect, useRef, useState } from 'react';

// third-party
import { useForm } from "react-hook-form"
import moment from "moment";
import Dropzone from "react-dropzone";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// application
import { carTransmission } from '../../data/generalData';
import { CloudUploadSvg, RemoveSvg } from "../../svg";
import cleanFormData from '../../helper/general-helper';
import * as GENERAL from '../../api/general';
import theme from '../../data/theme';
import { useDispatch, useSelector } from 'react-redux';
import BlockLoader from '../blocks/BlockLoader';

const inputRef = React.createRef();

export default function SellCarForm(props) {
    const { handleSubmit, register, errors, setError, reset } = useForm();
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedTransmission, setSelectedTransmission] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [brandList, setBrandList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [yearList, setYearList] = useState([]);
    // const [fileNames, setFileNames] = useState([]);
    // const [file, setFile] = useState();
    const [imageError, setImageError] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobilePrefix, setMobilePrefix] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [itemid, setItemid] = useState(1);
    const [imageObject, setImageObject] = useState([]);
    const [whatsAppDisabled, setWhatsAppDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [modelLoading, setModelLoading] = useState(true);

    const login_name = useSelector(state => state?.user?.profile?.name)
    const login_prefix = useSelector(state => state?.user?.profile?.mobilePrefix)
    const login_mobile = useSelector(state => state?.user?.profile?.mobile)
    const login_email = useSelector(state => state?.user?.profile?.email)
    const token = localStorage.getItem("access_token");

    const transmissionList = carTransmission();
    const mobileInputRef = useRef("");

    useEffect(() => {
        if (token) {
            setMobileNumber(login_prefix && login_mobile ? login_prefix + login_mobile : "");
            setMobilePrefix(login_prefix ? login_prefix : "");
        }
        let params = {
            per_page: "unlimited",
            sort_by: "popular"
        }
        GENERAL.getBrandList(params)
            .then(res => {
                setBrandList(res.data.data);
                let list = [];
                let currentYear = moment().format("YYYY");
                let startYear = moment(new Date().setFullYear(new Date().getFullYear() - 25)).format("YYYY");
                while (startYear <= currentYear) {
                    list.push(startYear++)
                }
                list = ["1995 and older", ...list]
                setYearList(list);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })

    }, [])

    useEffect(() => {
        if (selectedBrand != "") {
            setModelLoading(true)
            let params = {
                per_page: "unlimited",
            }
            GENERAL.getModelListByBrand(selectedBrand, params)
                .then(res => {
                    setModelList(res.data.data);
                    setModelLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }, [selectedBrand])

    useEffect(() => {
        if (name != "" && mobileNumber != "" && email != "" && selectedModel != "" && selectedBrand != "" && selectedYear != "" && selectedTransmission != "") {
            if (mobileNumber.length > 5) {
                setWhatsAppDisabled(false)
            } else {
                setWhatsAppDisabled(true)
            }
        }
    }, [selectedTransmission, selectedYear, selectedBrand, selectedModel, mobileNumber, email, name])

    if (loading) {
        return <BlockLoader />;
    }

    // when send message to whatsapp send one copy to db as well
    const sendToWhatsApp = () => {
        // store record to db
        let mobile = mobileNumber != 0 ? mobileNumber.substring(1) : "";
        let formData = {
            name: name,
            email: email,
            brand_id: selectedBrand,
            model_id: selectedModel,
            transmission: selectedTransmission,
            year: selectedYear,
            mobile_number: mobile,
            mobile_number_country: mobilePrefix,
            enquiry_type: 1
        }
        cleanFormData(formData);
        submitEnquiry(formData, "whatsapp");

        // send to whatsapp
        let presetMessage = "";
        presetMessage = "Hi I would like to check my used car price."
        presetMessage += `\r\nBrand : ${brandList.find(brand => brand.id == selectedBrand).name}`
        presetMessage += `\r\nModel : ${modelList.find(model => model.id == selectedModel).name}`
        presetMessage += `\r\nManufactured Year : ${selectedYear}`
        presetMessage += `\r\nTransmission : ${transmissionList.find(trans => trans.value == selectedTransmission).label}`
        presetMessage += `\r\nName : ${name}`
        presetMessage += `\r\nEmail : ${email}`

        window.open("https://api.whatsapp.com/send?phone=" + theme.whatsapp.sell + "&text=" + encodeURI(presetMessage), "_blank")
    }

    const handleMobileChange = (value, country) => {
        setMobileNumber(value);
        setMobilePrefix(country.countryCode);
    };

    const handleDrop = (acceptedFiles) => {
        let tempid = itemid;
        let promises = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            let promise = new Promise(resolve => {
                let reader = new FileReader();
                let file = acceptedFiles[i];
                reader.onload = () => {
                    resolve({
                        id: tempid + i + 1,
                        file: file,
                        filename: file.name,
                        image_url: reader.result
                    })
                };
                reader.readAsDataURL(file);
            })
            promises.push(promise)
        }
        Promise.all(promises).then(a => {
            setItemid(tempid + acceptedFiles.length + 1);
            setImageObject([...imageObject, ...a])
        })
    };

    const removeFileUpload = (id) => {
        setImageObject(
            imageObject.filter(image => {
                return image.id !== id;
            })
        );
    };

    const onSubmit = (data) => {
        setMobileError("")
        setImageError("");
        let valid = true;
        // if (imageObject.length == 0) {
        //     valid = false;
        //     setImageError("Image is required. Max 10 images can be uploaded.")
        // }
        let mobile = mobileNumber != 0 ? mobileNumber.substring(1) : "";
        if (!mobilePrefix || !mobile || mobile.length == 0) {
            valid = false;
            setMobileError("Mobile Number is required")
        }
        if (valid) {
            let formData = {
                ...data,
                images: imageObject.length > 0 ? imageObject : undefined,
                mobile_number: mobile,
                mobile_number_country: typeof parseInt(mobilePrefix) == "number"
                    ? (mobileInputRef.current?.state?.selectedCountry?.iso2 == undefined
                        ? "my"
                        : mobileInputRef.current?.state?.selectedCountry?.iso2)
                    : mobilePrefix,
                enquiry_type: 1
            }
            cleanFormData(formData);

            submitEnquiry(formData, "website");
            // console.log(formData);
        }
    }

    const submitEnquiry = (data, type) => {
        if (type == "website") { setApiLoading(true); }
        GENERAL.sendSellCarEnquiry(data)
            .then(res => {
                if (type == "website") {
                    setApiLoading(false);
                    setSubmitted(true);
                    toast.success("Request submitted successfully. Please wait for our agent to contact you.", { autoClose: 3000 });
                    props.history.replace("/");
                } else if (type == "whatsapp") {
                    reset();
                }
            })
            .catch(err => {
                showErrMsg(err)
            });
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        } else {
            toast.error("Something wrong.Please try again later.");
        }
        // else if (err.data.message) {
        //     toast.error(err.data.message);
        // } else {
        //     if (!checkUnauthorized(err, history)) {
        //         toast.error("Something wrong.Please try again later.");
        //     }
        // }
    };

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            if (key == 'mobile_number' || key == 'phone') {
                setMobileError(errors[errorKey].message)
            } else if (key == 'images') {
                setImageError("Please try another image.")
            }
            else {
                return Object.keys(validationErrors).forEach((key) => {
                    setError(key, {
                        type: "server",
                        message: errors[errorKey].message
                    });
                });

            }
        }
    };

    return (
        <React.Fragment>

            <div className="block" style={{ paddingTop: "46px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Sell Your Car</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select className={`form-control ${errors.brand_id == null ? '' : 'is-invalid'}`}
                                                        name="brand_id" ref={register({ required: true })} defaultValue=""
                                                        onChange={(e) => { setSelectedBrand(e.target.value); }}>
                                                        <option value="">Select Brand *</option>
                                                        {brandList.map(brand => (
                                                            <option key={brand.id} value={brand.id}>
                                                                {brand.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.brand_id == null ? '' : <div className="invalid-feedback">Brand is required</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select className={`form-control ${errors.model_id == null ? '' : 'is-invalid'}`}
                                                        name="model_id" ref={register({ required: true })} defaultValue=""
                                                        onChange={(e) => { setSelectedModel(e.target.value); }} disabled={selectedBrand == "" || modelLoading == true}>
                                                        <option value="">Select Model *</option>
                                                        {modelList.map(model => (
                                                            <option key={model.id} value={model.id}>
                                                                {model.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.model_id == null ? '' : <div className="invalid-feedback">Model is required</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select className={`form-control ${errors.year == null ? '' : 'is-invalid'}`}
                                                        name="year" ref={register({ required: true })} defaultValue=""
                                                        onChange={(e) => setSelectedYear(e.target.value)} disabled={selectedBrand == "" || selectedModel == ""}>
                                                        <option value="">Select Year *</option>
                                                        {yearList.map((year, index) => (
                                                            <option key={'year' + index} value={year == '1995 and older' ? '1995' : year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.year == null ? '' : <div className="invalid-feedback">Year is required</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <select className={`form-control ${errors.transmission == null ? '' : 'is-invalid'}`}
                                                        name="transmission" ref={register({ required: true })} defaultValue=""
                                                        onChange={(e) => setSelectedTransmission(e.target.value)}
                                                        disabled={selectedBrand == "" || selectedModel == "" || selectedYear == ""}
                                                    >
                                                        <option value="">Select Transmission *</option>
                                                        {transmissionList.map(trans => (
                                                            <option key={trans.value} value={trans.value}>
                                                                {trans.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.transmission == null ? '' : <div className="invalid-feedback">Car Transmission is required</div>}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        className={`form-control ${errors.name == null ? '' : 'is-invalid'}`}
                                                        id="name" name="name" type="text" placeholder="Enter Full Name *" onChange={(e) => setName(e.target.value)}
                                                        ref={register({
                                                            required: { value: true, message: "Full Name is required" },
                                                            maxLength: { value: 255, message: "Full Name should not exceed 255 characters" },
                                                            minLength: { value: 2, message: "Full Name should more than 2 characters" },
                                                        })}
                                                        defaultValue={token && login_name ? login_name : ""}
                                                    />
                                                    {errors.name == null ? '' : <div className="invalid-feedback">{errors.name.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        className={`form-control ${errors.email == null ? '' : 'is-invalid'}`}
                                                        id="email" name="email" type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}
                                                        ref={register({
                                                            required: { value: false, message: "Email is required" },
                                                            maxLength: { value: 255, message: "Email should not exceed 255 characters" },
                                                            minLength: { value: 2, message: "Email should more than 2 characters" },
                                                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                                                        })}
                                                        defaultValue={token && login_email ? login_email : ""}
                                                    />
                                                    {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <PhoneInput
                                                        inputClass={"form-control"}
                                                        ref={mobileInputRef}
                                                        value={mobileNumber ? mobileNumber : ""}
                                                        onlyCountries={["my", "sg"]}
                                                        country={mobileNumber ? "" : "my"}
                                                        onChange={(value, country) => handleMobileChange(value, country)}
                                                        autoFormat={false}
                                                        inputProps={{ required: true }}
                                                        // preferredCountries={["my", "sg"]}
                                                        countryCodeEditable={false}
                                                    />
                                                    {mobileError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{mobileError}</span></div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <label htmlFor="image" className="mb-2" style={{ fontSize: "14px", color: "#8a9198" }}>
                                                    File size do not exceed 10MB <br /> File types accepted : .JPG, .JPEG, .PNG <br /> Max 10 images can be uploaded
                                                        </label>

                                                {imageObject.length < 10 ?
                                                    <Dropzone onDrop={handleDrop} accept="image/jpg, image/jpeg, image/png" minSize={1} maxSize={10240000} maxFiles={10}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <>
                                                                <div
                                                                    {...getRootProps({ className: "dropzone" })}
                                                                    className="mt-1"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        outline: "none",
                                                                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B7BEC5FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                                                                    }}>
                                                                    <input {...getInputProps()} multiple={true} />
                                                                    <div style={{ padding: "25px 40px", color: "#8a9198" }}>
                                                                        {imageObject.length < 10 ? (
                                                                            <>
                                                                                <CloudUploadSvg width="38px" fill="#8a9198" style={{ display: "inline", marginRight: "12px" }} />
                                                                                <p style={{ display: "inline" }}>
                                                                                    Drag and drop image here or <span className="text-danger">Browse Image</span>
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            <div>
                                                                                <p className="mb-0">Maximum 10 images can be uploaded</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropzone>
                                                    : null}
                                                {imageError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{imageError}</span></div>}
                                            </div>
                                            <div className="col-md-6"></div>

                                            <div className="col-md-12 d-flex">
                                                <div className="row pt-3">
                                                    {imageObject.map((image, index) => {
                                                        return (
                                                            <div key={image.id} className="mr-2 ml-2">
                                                                <div className="image-div-style">
                                                                    <img
                                                                        src={image.image_url}
                                                                        className="img-fluid avatar avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                                                        alt=""
                                                                        style={{ cursor: "pointer" }}
                                                                    />
                                                                    <span>
                                                                        <RemoveSvg width="38px" fill="#ffffff" style={{ display: "inline", marginRight: "12px" }} className="sell-car-edit-button" onClick={() => { removeFileUpload(image.id) }} />
                                                                    </span>

                                                                </div>
                                                                {/* {index == 0 ? <span><p className="sell-car-image-badge">Cover</p></span> : null} */}
                                                            </div>
                                                        )
                                                    })}
                                                    {imageObject.length > 0 && imageObject.length < 10 ?
                                                        <div>
                                                            <div className="product-add-image-btn">
                                                                <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" onClick={() => inputRef.current.click()}>Add Image</button>
                                                            </div>
                                                            <input className='fileinput' type="file" name={'image'} hidden ref={inputRef} accept="image/png,image/jpeg,image/jpg" onChange={(e) => handleDrop(e.target.files)} />
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {apiLoading ? (
                                                <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            ) : (
                                                <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" id={"submit in sell car form"} onClick={handleSubmit(onSubmit)}>
                                                    {submitted == false ? 'Submit' : 'Submitted Sell'}
                                                </button>)}
                                            <button type="button" className="btn btn-success ml-2 mt-2 mt-md-3 mt-lg-4" id={"sell car whatsapp"} style={{ width: '50%' }} disabled={whatsAppDisabled} style={{ cursor: whatsAppDisabled == true ? 'not-allowed' : 'pointer' }} onClick={() => sendToWhatsApp()}>
                                                {/* <a
                                                    type="button"
                                                    target="_blank"
                                                    href={"https://api.whatsapp.com/send?phone=" + theme.whatsapp.buy + "/?text=" + getPresetMessage()}
                                                    className={"mr-2"}
                                                >
                                                    <i className="fab fa-whatsapp" style={{ paddingRight: '5px' }} />
                                                    WhatsApp for enquiry
                                                </a> */}
                                                <span className='d-none d-sm-block' id={"sell car whatsapp"}>WhatsApp for enquiry</span>
                                                <span className='d-block d-sm-none' id={"sell car whatsapp"}>WhatsApp</span>

                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}