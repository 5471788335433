// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import moment from 'moment'
// data stubs
import theme from '../../data/theme';
import { carTransmission } from "../../data/generalData"

export default function AccountPageEnquiryDetails(props) {
    const [enquiryDetail, setEnquiryDetail] = useState(props.location.state.data);
    const [imageObject, setImageObject] = useState([]);

    const carTransmissionList = carTransmission();

    useEffect(() => {
        if (enquiryDetail.images && enquiryDetail.images.length > 0) {
            let a = [], image_id = 1;
            for (let i = 0; i < enquiryDetail.images.length; i++) {
                a.push({
                    id: image_id,
                    url: enquiryDetail.images[i].url
                })
                image_id = image_id + 1;
            }
            setImageObject(a);
        }
    }, [enquiryDetail])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`Enquiry Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/enquiry" className="btn btn-xs btn-secondary">Back to list</Link>
                    </div>
                    <h5 className="order-header__title">{enquiryDetail.enquiry_type == 1 ? "Sell Car Enquiry" : "Buy Car Enquiry"}</h5>
                    <div className="order-header__subtitle">
                        Enquiry Date :
                        {' '}
                        <mark className="order-header__date">{moment(enquiryDetail.created_at).format("YYYY-MM-DD hh:mm A")}</mark>
                        {' '}
                    </div>
                    <div className="order-header__subtitle">
                        Enquiry Status :
                        {' '}
                        <mark className="order-header__date">{enquiryDetail.status.name}</mark>
                        {' '}
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row pl-3 pb-3">
                        <div className="col-6 col-md-3 p-1">
                            <div className="title">Vehicle Brand</div>
                            <b>{enquiryDetail.brand_info.name}</b>
                        </div>
                        <div className="col-6 col-md-3 p-1">
                            <div className="title">Vehicle Model</div>
                            <b>{enquiryDetail.model_info.name}</b>
                        </div>
                        <div className="col-6 col-md-3 p-1">
                            <div className="title">Vehicle Year</div>
                            <b>{enquiryDetail.year}</b>
                        </div>
                        <div className="col-6 col-md-3 p-1">
                            <div className="title">Vehicle Transmission</div>
                            <b>{carTransmissionList.find(trans => trans.value == enquiryDetail.transmission).label}</b>
                        </div>
                    </div>
                    <div className="card-divider"></div>
                    {enquiryDetail.website_url ?
                        <div className="col-6 col-md-12 pt-3">
                            <div className="title">Website url</div>
                            <Link to={enquiryDetail.website_url}>{enquiryDetail.website_url}</Link>
                        </div>
                        : null}
                    {imageObject.length ?
                        <>
                            <div className="row pl-3 pt-3">
                                Vehicle Image
                            </div>
                            <div className="col-md-12 d-flex">
                                <div className="row pt-3">
                                    {imageObject.map((image, index) => {
                                        return (
                                            <div className="image-div-style" key={image.id}>
                                                <img
                                                    src={image.url}
                                                    className="img-fluid avatar avatar-large mt-3 mt-sm-0 mr-2 mb-3 image-style"
                                                    alt=""
                                                    onClick={() => window.open(image.url, "_blank")}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                        : null}
                </div>

            </div>

            {/* <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                            <div className="address-card__name">Helena Garcia</div>
                            <div className="address-card__row">
                                Random Federation
                                <br />
                                115302, Moscow
                                <br />
                                ul. Varshavskaya, 15-2-178
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">38 972 588-42-36</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">stroyka@example.com</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                            <div className="address-card__name">Helena Garcia</div>
                            <div className="address-card__row">
                                Random Federation
                                <br />
                                115302, Moscow
                                <br />
                                ul. Varshavskaya, 15-2-178
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">38 972 588-42-36</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">stroyka@example.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );
}
