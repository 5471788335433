// react
import React, { useCallback, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
    // RefreshSvg
} from '../../svg';
import { sidebarOpen } from '../../store/sidebar';
import BrandCard from '../shared/BrandCard';
import InfiniteScroll from "react-infinite-scroll-component";

function useSetOption(option, filter, dispatch) {
    const callback = useCallback(filter, []);

    return useCallback((data) => {
        dispatch({
            type: 'SET_OPTION_VALUE',
            option,
            value: callback(data),
        });
    }, [option, callback, dispatch]);
}

function BrandView(props) {
    const {
        isLoading,
        brandList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
        type
    } = props;
    const [layout, setLayout] = useState(propsLayout);

    const handleLoadMore = useSetOption('page', parseFloat, dispatch);
    const handleSortChange = useSetOption('sort', (event) => event.target.value, dispatch);
    // const handleLimitChange = useSetOption('limit', (event) => parseFloat(event.target.value), dispatch);

    // const handleResetFilters = useCallback(() => {
    //     dispatch({ type: 'RESET_FILTERS' });
    // }, [dispatch]);

    const filtersCount = Object.keys(filters).map((x) => filters[x]).filter((x) => x).length;
    let viewModes = [
        { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
        { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
    ];

    viewModes = viewModes.map((viewMode) => {
        const className = classNames('layout-switcher__button', {
            'layout-switcher__button--active': layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });

    const productsListItems = brandList ? brandList.data.map((product) => (
        <div key={product.id} className="products-list__item">
            <BrandCard product={product} />
        </div>
    )) : null;

    const rootClasses = classNames('products-view', {
        'products-view--loading': isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });


    let content;

    if (productsListItems && productsListItems.length > 0) {
        content = (
            <div className="products-view__content">
                <InfiniteScroll
                    dataLength={productsListItems.length}
                    next={() => handleLoadMore(brandList.current_page + 1)}
                    style={{ overflow: 'hidden' }}
                    hasMore={brandList.next_page_url ? true : false}
                >

                    {/* <div className="products-view__options">
                        <div className={viewOptionsClasses}>
                            <div className="view-options__control">
                                <label htmlFor="view-options-sort">Sort By</label>
                                <div
                                    className={options.sort && options.sort.split("_")[0] === "popular" && options.sort.split("_")[1] === "desc" ? "opt-box form-control-sm active" : "opt-box form-control-sm"}
                                    onClick={() => handleSortChange({ target: { value: options.sort && options.sort.split("_")[0] === "popular" && options.sort.split("_")[1] === "desc" ? "popular_asc" : "popular_desc" } })}>
                                    Popular
                                </div>
                                <div>
                                    <select
                                        id="view-options-sort"
                                        className="form-control form-control-sm"
                                        value={options.sort || brandList.sort}
                                        onChange={(e) => { handleSortChange({ target: { value: e.target.value } }); }}
                                    >
                                        <option value="default">Default</option>
                                        <option value="name_asc">Name (A-Z)</option>
                                        <option value="name_desc">Name (Z-A)</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    <div
                        className="products-view__list products-list"
                        data-layout={layout !== 'list' ? grid : layout}
                        data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                    >
                        <div className="products-list__body">
                            {productsListItems}
                        </div>
                    </div>
                </InfiniteScroll>

                {/* {brandList.next_page_url ? (
                    <div className="products-view__pagination">
                        <div className="col-12 text-center">
                            <button className={"load-more-btn"}
                                onClick={() => handleLoadMore(brandList.current_page + 1)}
                            >
                                <RefreshSvg /> 
                                Load More
                                    </button>
                        </div>
                    </div>
                ) : null} */}
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">{type == "all-brand" ? "No Brand Found" : "No Car Found"}</div>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
        </div>
    );
}

BrandView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * brandList object.
     */
    brandList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

BrandView.defaultProps = {
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(BrandView);
