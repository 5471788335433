// react
import React, { useEffect, useReducer, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import * as PRODUCT from '../../api/product';
import { sidebarClose } from '../../store/sidebar';

// data stubs
import theme from '../../data/theme';

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === 'string') {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === 'string') {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === 'string') {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [
        parseQueryOptions(location),
        parseQueryFilters(location),
    ];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== 'default') {
        params.sort = options.sort;
    }

    Object.keys(filters).filter((x) => x !== 'category' && !!filters[x]).forEach((filterSlug) => {
        params[`filter_${filterSlug}`] = filters[filterSlug];
    });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    brandIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_PRODUCT_LIST':
            return { ...state, brandListIsLoading: true };
        case 'FETCH_PRODUCT_LIST_SUCCESS':
            return { ...state, brandListIsLoading: false, productsList: action.productsList };
        case 'SET_PRODUCT_OPTION_VALUE':
            return {
                ...state,
                options: { ...state.options, ppage: 1, [action.option]: action.value },
            };
        case 'SET_OPTION_VALUE':
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case 'SET_FILTER_VALUE':
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case 'RESET_FILTERS':
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case 'RESET':
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageAllProduct(props) {
    const {
        columns,
        viewMode,
        sidebarPosition,
        product
    } = props;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const brandName = "All Car";
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [loading, setLoading] = useState(true);

    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? '?' : ''}${query}`;
        if (brandName !== "All Car") {
            window.history.replaceState(null, '', location);
        }
    }, [state.options, state.filters]);

    useEffect(() => {
        let canceled = false;

        let currentPage = state.options.ppage;
        let params = {
            per_page: 12,
            // sort_by: 'is_popular',
            page: currentPage ? currentPage : 1
            // sort_type : 'asc'
        }
        if (state.options.sort) {
            let sortArr = state.options.sort.split("_");
            params.sort_by = sortArr[0];
            params.sort_type = sortArr[1];
        }

        dispatch({ type: 'FETCH_PRODUCT_LIST' });

        PRODUCT.getProductList(params)
            .then((res) => {
                let list = res.data;
                if (currentPage > 1 && state.productsList) {
                    list.data = [...state.productsList.data, ...list.data];
                }
                dispatch({ type: 'FETCH_PRODUCT_LIST_SUCCESS', productsList: list });
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })

        return () => {
            canceled = true;
        };
    }, [dispatch, product, state.options, state.filters]);

    if (loading || (state.brandListIsLoading && !state.productsList)) {
        return <BlockLoader />;
    }

    let pageTitle = brandName;
    let content;

    const productsView = (
        <ProductsView
            isLoading={state.brandListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
            offcanvas={offcanvas}
        // type="all-brand"
        />
    );

    // const sidebarComponent = (
    //     <CategorySidebar offcanvas={offcanvas}>
    //         <CategorySidebarItem>
    //             <WidgetFilters
    //                 title="Filters"
    //                 offcanvas={offcanvas}
    //                 filters={state.productsList.filters}
    //                 values={state.filters}
    //                 dispatch={dispatch}
    //             />
    //         </CategorySidebarItem>
    //         {offcanvas !== 'always' && (
    //             <CategorySidebarItem className="d-none d-lg-block">
    //                 <WidgetProducts title="Latest Products" products={latestProducts} />
    //             </CategorySidebarItem>
    //         )}
    //     </CategorySidebar>
    // );

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {/* {sidebarComponent} */}
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar">
                {/* {sidebarComponent} */}
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`All Car — ${theme.name}`}</title>
            </Helmet>

            <div style={{ paddingTop: "46px" }}>
                <PageHeader header={pageTitle}
                // breadcrumb={breadcrumb} 
                />
                {content}
            </div>

        </React.Fragment>
    );
}

ShopPageAllProduct.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageAllProduct.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
    // categories: state.shop.categories,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageAllProduct);
