import React from "react";
import { Link } from "react-router-dom";

function LogoImage() {
  return (
    <Link to={`/`}>
      <img src="images/logos/duitcar_logo.png" alt="logo" className="logo" style={{width:"90%",height:"auto"}}/>
    </Link>
  );
}

export default LogoImage;
