// import CookieService from "../services/CookieService"

export default function authHeader() {
    // return authorization header with jwt token
    const token = JSON.parse(localStorage.getItem('access_token'));
    if (token) {
        return { Authorization: `Bearer ${token.access_token}` };
    } else {
        return {};
    }
}