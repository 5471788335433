// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import BookingForm from "../shared/BookingForm";
import { getAllModelList } from "../../api/general";
import BlockLoader from '../blocks/BlockLoader';
import { allEqual } from '../../services/utils';
class Product extends Component {
    constructor(props) {
        super(props);
        const { product } = props;

        this.state = {
            // quantity: 1,
            activeImg: null,
            oriPrice: product.price[0],
            displayPrice: product.variant_info?.length == 1
                ? product.is_sales
                    ? product.sales_price[0]
                    : product.price[0]
                : product.is_sales && allEqual(product.sales_price)
                    ? product.sales_price[0]
                    : !product.is_sales && allEqual(product.price)
                        ? product.price[0]
                        : 0,
            images: product.images,
            selectedVariant: product.variant_choices?.length == 1 ? [product.variant_choices[0]?.uuid] : [], //store variant
            selectedVariantID:
                product.variant_info.length == 1 ? product.variant_choices[0]?.uuid : null, //store variant id
            stock: 0,
            openModel: false,
            modelList: [],
            loading: true
        };
    }

    componentDidMount() {
        this.getModelList();
        this.countTotalStock();
    }

    getModelList() {
        let params = { per_page: 'unlimited' }
        getAllModelList(params)
            .then(res => {
                this.setState({ modelList: res.data.data, loading: false })
            })
            .catch(err => {
                this.setState({ modelList: [], loading: false })
            })
    }


    handleOnHoverOption(variant, index) {
        if (index == 0 && variant.image) this.setState({ activeImg: variant.image.url });
        else this.setState({ activeImg: null });
    }

    hvStock(variantID, order) {
        const { product } = this.props;
        const { selectedVariant } = this.state;
        if (selectedVariant.length > 0) {
            let currentVariantOrder = order == 1 ? 0 : 1;
            let matchCombinations = product.variant_choices.find((item) => {
                return (
                    item.combinations[order]?.uuid == variantID &&
                    item.combinations[currentVariantOrder]?.uuid ==
                    selectedVariant[currentVariantOrder]
                );
            });
            if (matchCombinations) {
                if (matchCombinations.stock_count == 0) return false;
            }
            return true;
        } else {
            return true;
        }
    }

    handleVariantChange = (order, item) => {
        const { product } = this.props;
        const { images, selectedVariant } = this.state;
        let imgArr = images;
        let cvariant = [...this.state.selectedVariant]; // copy selectedVariant array
        if (order == 1) { // first variant (got image)
            if (imgArr.find((img) => img.id == item.image.id)) { // if the image found in imgArr
                imgArr = imgArr.slice(1); // remove from imgArr
            } else { // image not found
                imgArr.unshift(item.image) // push the image to array[0]
                this.countTotalStock();
            }
        }
        let index = cvariant.indexOf(item.uuid);
        if (index == -1) { // if uuid not exist
            cvariant[order - 1] = item.uuid // set uuid to selectedVariant
        } else {
            cvariant[order - 1] = null // remove the uuid from cvariant
        }
        this.setState({ images: imgArr, selectedVariant: cvariant })

        // find stock & price
        if (cvariant.length == product.variant_info.length) {
            let cvariantS = cvariant.join(",")
            product.variant_choices.map(vari => {
                vari.target = vari.combinations.map(com => com.uuid).join(",")
                return vari
            })
            if (product.variant_choices.find((choice) => choice.target == cvariantS)) {
                let selectedChoice = product.variant_choices.find(
                    (choice) => choice.target == cvariantS
                );
                this.setState({
                    stock: selectedChoice.stock_count,
                    quantity: 1,
                    oriPrice: selectedChoice.retail_price,
                    displayPrice: selectedChoice.is_sales
                        ? selectedChoice.offer_price
                        : selectedChoice.retail_price,
                    selectedVariantID: selectedChoice.uuid,
                });
            } else {
                this.setState({ selectedVariantID: null });
                this.countTotalStock()
            }
        } else {
            this.setState({ selectedVariantID: null });
            this.countTotalStock()
        }
    }

    countTotalStock() {
        const { product, stock } = this.props;
        let numStock = 0;
        product.variant_choices.map((item) => {
            numStock += item.stock_count;
        });
        if (stock !== numStock) {
            this.setState({
                stock: numStock,
            });
        }
    }

    renderVariant(item, index, label) {
        const { product } = this.props;
        const { selectedVariant, activeImg } = this.state;

        return (
            <div key={item.hash_id} className="form-group product__option">
                <div className="product__option-label">{label?.name}</div>

                <div className="variant-box__list">
                    {label?.options.map((opt) => {
                        return (
                            <label
                                key={opt.uuid}
                                className={"variant-box"}
                                onMouseEnter={() => this.handleOnHoverOption(opt, index)}
                                onMouseLeave={() => (activeImg ? this.setState({ activeImg: null }) : null)}
                                className={"variant-box " + (this.hvStock(opt.uuid, label.order - 1) ? (selectedVariant.includes(opt.uuid) ? "active" : "") : "disabled")}
                                onClick={() => this.hvStock(opt.uuid, label.order - 1) ? this.handleVariantChange(label.order, opt) : null}
                            >
                                <div>{opt.name}</div>
                                <div className="selected-triangle" />
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }


    renderBookingModel() {
        const { openModel, modelList } = this.state;
        const { product } = this.props;
        return (
            <Modal isOpen={openModel} toggle={() => this.setState({ openModel: false })} centered>
                <ModalHeader toggle={() => this.setState({ openModel: false })}></ModalHeader>
                <ModalBody>
                    <div>
                        <BookingForm data={product} model={modelList} closeModal={() => this.setState({ openModel: false })} />
                    </div>
                </ModalBody>
            </Modal>

        )
    }

    render() {
        const {
            product,
            layout,
            history
        } = this.props;
        const { quantity, activeImg, images, stock, selectedVariant, displayPrice, selectedVariantID, loading, oriPrice, } = this.state;
        let prices;

        prices =
            selectedVariant.length == product.variant_info.length && selectedVariantID ? (
                product.is_sales ? (
                    <>
                        <span className="product__new-price">
                            <Currency value={displayPrice} />
                        </span>
                        {!allEqual([displayPrice, oriPrice]) ? (
                            <span className="product__old-price">
                                <Currency value={oriPrice} />
                            </span>
                        ) : null}
                    </>
                ) : (
                    <Currency value={displayPrice} />
                )
            ) : product.is_sales ? (
                !allEqual(product.sales_price) ? (
                    <>
                        <Currency value={product.sales_price[0]} />{" "}
                        {product.sales_price[product.sales_price.length - 1] &&
                            product.sales_price.length > 1 ? (
                            <>
                                {" - "}
                                <Currency
                                    value={product.sales_price[product.sales_price.length - 1]}
                                />
                            </>
                        ) : null}
                    </>
                ) : (
                    <Currency value={product.sales_price} />
                )
            ) : !allEqual(product.price) ? (
                <>
                    <Currency value={product.price[0]} />{" "}
                    {product.price[product.price.length - 1] && product.price.length > 1 ? (
                        <>
                            {" - "}
                            <Currency value={product.price[product.price.length - 1]} />
                        </>
                    ) : null}
                </>
            ) : (
                <Currency value={product.price} />
            );

        return loading ? (
            <BlockLoader />
        ) : (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={images} activeImg={activeImg} />

                    <div className="product__info">
                        <h1 className="product__name">{product.id ? `${product.id})`: ""}{product.name}</h1>
                        <div className="product__rating">
                            {/* <div className="product__rating-stars">
                                <Rating value={product.rating} />
                            </div>
                            <div className="product__rating-legend">
                                <Link to="/">{`${product.reviews} Reviews`}</Link>
                                <span>/</span>
                                <Link to="/">Write A Review</Link>
                            </div> */}
                        </div>
                        <div className="product__description">
                            <div className="product-desc" dangerouslySetInnerHTML={{ __html: product.short_description }} />
                        </div>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__prices">
                            {prices}
                        </div>

                        <form className="product__options">
                            {product.variant_info?.length == 1 &&
                                product.variant_info[0]?.options.length == 1
                                ? null
                                : product.variant_info.map((item, index) => {
                                    return this.renderVariant(
                                        item,
                                        index,
                                        product.variant_info[index]
                                    );
                                })}
                            <div className="stock-info">{stock} available in stock</div>
                            {/* <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => cartAddItem(product, [], quantity)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={!quantity}
                                                    className={classNames('btn btn-primary btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    Add to cart
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </form>
                    </div>

                    <div className="product__footer">
                        <div style={{ width: '100%' }}>
                            <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" style={{ width: 'inherit' }} onClick={() => this.setState({ openModel: true })}>
                                Chat With US
                            </button>
                        </div>
                        {/* <div className="product__tags tags">
                            <div className="tags__list">
                                <Link to="/">Mounts</Link>
                                <Link to="/">Electrodes</Link>
                                <Link to="/">Chainsaws</Link>
                            </div>
                        </div> */}

                        {/* <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like"><Link to="/">Like</Link></li>
                                <li className="share-links__item share-links__item--type--tweet"><Link to="/">Tweet</Link></li>
                                <li className="share-links__item share-links__item--type--pin"><Link to="/">Pin It</Link></li>
                                <li className="share-links__item share-links__item--type--counter"><Link to="/">4K</Link></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                {this.renderBookingModel()}
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(Product);
