import { UPDATE_USER,UPDATE_TOKEN,UPDATE_NOTIFICATION } from './userActionTypes';

const initialState = {
    profile:null,
    token:false,
    notification:0
};

export default function userReducer(state = initialState, action) {
    if (action.type === UPDATE_USER ) {
        return { ...state, profile: action.profile };
    }
    else if (action.type === UPDATE_TOKEN ) {
        return { ...state,token: action.token };
    }
    else if (action.type === UPDATE_NOTIFICATION ) {
        return { ...state,notification: action.notification };
    }
    return state;
}
