// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// application
import Indicator from './Indicator';
import { connect } from "react-redux";
import { Person20Svg } from '../../svg';
import { updateUser, updateToken } from "../../store/user";
import { mobileUserMenuOpen } from "../../store/mobile-menu";
import * as AUTH from '../../api/auth';
import * as USER from '../../api/user';
import { getDefaultAvatar } from '../../services/utils';
import accountMenuList from '../../data/accountMenuList';
import { checkUnauthorized, useWindowSize } from '../../helper/general-helper';
import LogRocket from "logrocket";

export function IndicatorAccount(props) {
    const history = useHistory();
    // const { user } = props;
    const { from, user, updateToken, updateUser: updateUser, mobileUserMenuOpen: mobileUserMenuOpen } = props;
    // const [user, setUser] = useState([]);
    const token = localStorage.getItem("access_token");
    const { handleSubmit, register, errors, setError } = useForm();
    const [apiLoading, setApiLoading] = useState(false);
    const [closeIndicator, setCloseIndicator] = useState(false);
    const size = useWindowSize();

    // get user profile
    useEffect(() => {
        let canceled = false;
        if (token && from === "header") {
            // console.log("a")
            USER.getProfile()
                .then((res) => {
                    if (res.data.user_source_type.toString() !== process.env.REACT_APP_PORTAL_TYPE) {
                        // if user acc is not member type, force logout
                        AUTH.logout(history);
                    } else if (res.data) {
                        LogRocket.identify(res.data.uuid, {
                            name: res.data.full_name,
                            email: res.data.email,
                        });
                        updateUser({
                            title: res.data.title,
                            id: res.data.uuid,
                            firstName: res.data.first_name,
                            lastName: res.data.last_name,
                            name: res.data.full_name,
                            image: res.data.profile_image,
                            email: res.data.email,
                            mobilePrefix: res.data.mobile_prefix,
                            mobile: res.data.mobile_number,
                            role: res.data.roles,
                            profile_image: res.data.profile_image
                        });
                    }
                })
                .catch((err) => {
                    if (!checkUnauthorized(err, history)) {
                        if (err.http_code == 500) {
                            toast.error("Something went wrong, please try again later", { autoClose: "3000" });
                            AUTH.removeUserFromStorage();
                            history.push("/login");
                        } else if (err.http_code == 422) {
                            toast.error("We have encountered an error processing your request. Please try again later.", { autoClose: 3000 });
                        }
                    }
                });
        }
    }, [token]);

    const handleLogin = (data) => {
        setApiLoading(true);
        let formData = {
            ...data,
            portal_type: process.env.REACT_APP_PORTAL_TYPE,
        }
        console.log(formData)
        userLogin(formData);
    }

    const userLogin = (data) => {
        AUTH.login(data)
            .then(res => {
                toast.success("Login Successful", { autoClose: 3000 });
                history.push("/");
                setCloseIndicator(true);
                setApiLoading(false);
            })
            .catch(err => {
                showErrMsg(err)
            })
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        }
        else if (err.data.message) {
            toast.error(err.data.message, { autoClose: 3000 });
        }
        else if (err.http_code == 403 && err.data?.errors) {
            for (const errorKey in err.data?.errors) {
                toast.error(err.data?.errors[errorKey].message)
            }
        } else {
            toast.error("Something went wrong.Please try again later.", { autoClose: 3000 })
        }
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message
                });
            });
        }
    };

    const handleLogout = () => {
        toast.info("Logout Successful", { autoClose: 2000 });
        setCloseIndicator(true);
        AUTH.logout(history);
    }

    const handleCloseAfterRedirect = () => {
        setCloseIndicator(true);
        setTimeout(() => {
            setCloseIndicator(false);
        }, 500);
    };

    const LoginDropdown = (
        <div className="account-menu">
            <form className="account-menu__form">
                <div className="account-menu__form-title">Log In to Your Account</div>
                <div className="form-group">
                    <label htmlFor="header-signin-email" className="sr-only">Email address</label>
                    <input
                        id="header-signin-email"
                        type="email"
                        className={`form-control-sm form-control ${errors.email == null ? '' : 'is-invalid'}`}
                        ref={register({
                            required: { value: true, message: "Email is required" },
                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                        })}
                        name="email"
                        placeholder="Email address"
                    />
                    {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="header-signin-password" className="sr-only">Password</label>
                    <div className="account-menu__form-forgot">
                        <input
                            id="header-signin-password"
                            type="password"
                            className={`form-control-sm form-control ${errors.password == null ? '' : 'is-invalid'}`}
                            ref={register({
                                required: { value: true, message: "Password is required" },
                                min: { value: 8, message: "Password must be at least 8 characters" }
                            })}
                            name="password"
                            placeholder="Password"
                        />
                        <Link to="/forgot-password" className="account-menu__form-forgot-link">Forgot?</Link>
                        {errors.password == null ? '' : <div className="invalid-feedback">{errors.password.message}</div>}
                    </div>
                </div>
                <div className="form-group account-menu__form-button">
                    {apiLoading ? (
                        <button className="btn btn-primary btn-sm" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary btn-sm" onClick={handleSubmit(handleLogin)}>Login</button>)}
                </div>
                <div className="account-menu__form-link pb-2">
                    <Link to="/register">Create An Account</Link>
                </div>
                <div className="account-menu__form-link pt-3 border-top">
                    <Link to="/resend-verification">Resend Email Verification Link</Link>
                </div>
            </form>
        </div>
    );

    const avatar = token && user && user.profile_image ? user.profile_image : getDefaultAvatar();

    const ProfileDropDown = (
        <div className="account-menu">
            <Link to="/account/profile" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    <img src={avatar} alt="user-avatar" onError={(e) => (e.target.src = getDefaultAvatar())} />
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{user ? user.name : "Loading..."}</div>
                    {/* <div className="account-menu__user-email">{user ? (user.membersonPoint ? user.membersonPoint : 0) + " points" : "Loading..."}</div> */}
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                {accountMenuList.map((item) => {
                    return (
                        <li key={item.id}>
                            <Link to={item.url}>{item.name}</Link>
                        </li>
                    );
                })}
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="" onClick={handleLogout}>
                        Log Out
                    </Link>
                </li>
            </ul>
        </div>
    )

    return token ? (
        size.width < 767 ? (
            <Indicator
                icon={
                    <div className="user-avatar" onClick={() => mobileUserMenuOpen()}>
                        <img src={avatar} onError={(e) => (e.target.src = getDefaultAvatar())} alt="user-avatar" />
                    </div>
                }
            />
        ) : (
            <Indicator
                dropdown={ProfileDropDown}
                icon={
                    <div className="user-avatar">
                        <img src={avatar} onError={(e) => (e.target.src = getDefaultAvatar())} alt="user-avatar" />
                    </div>
                }
            />
        )
        // <Indicator dropdown={ProfileDropDown} icon={<Person20Svg />} />
    ) : size.width < 767 ? (
        <Indicator
            icon={
                <div className="user-avatar" onClick={() => history.push("/login")}>
                    <img src={avatar} onError={(e) => (e.target.src = getDefaultAvatar())} alt="user-avatar" />
                </div>
            }
        />
    ) : (
        <Indicator
            dropdown={LoginDropdown}
            closeIndicator={closeIndicator}
            icon={
                <div className="user-avatar">
                    <img src={avatar} onError={(e) => (e.target.src = getDefaultAvatar())} alt="user-avatar" />
                </div>
            }
        />
        // (
        // <Indicator url="/account" dropdown={LoginDropdown} icon={<Person20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    user: state.user.profile,
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    updateUser,
    updateToken,
    mobileUserMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
