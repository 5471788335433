// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            <div className="footer-contacts__text">
                {theme.about.info}
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {theme.contacts.address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {/* {theme.contacts.email} */}
                    {`${theme.contacts.buy_email} , ${theme.contacts.sell_email}`}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    {`${theme.contacts.buy_phone}, ${theme.contacts.sell_phone}`}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    {theme.operation.time}
                </li>
                <div className="footer-newsletter__text footer-newsletter__text--social">
                    Follow us on social networks
                </div>
                <ul className="social-links__list social-links--shape--circle footer-newsletter__social-links">
                    <li className="social-links__item social-icons mt-2 social-links__link social-links__link--type--facebook">
                        <a
                            href={theme.social.facebook_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-facebook-f social-icon" />
                        </a>
                    </li>
                </ul>
            </ul>
        </div>
    );
}
