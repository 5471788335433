// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import { useForm } from "react-hook-form";
import cleanFormData from '../../helper/general-helper';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as USER from "../../api/user";

export default function AccountPagePassword() {
    const { handleSubmit, register, errors, setError, reset } = useForm();
    const [password, setPassword] = useState("");
    const [apiLoading, setApiLoading] = useState(false);

    const validatePasswordMatch = (newpass) => {
        return password === newpass;
    }

    const onSubmit = (data) => {
        // console.log(data)
        let formData = {
            ...data
        }
        cleanFormData(formData)

        USER.changeMyPassword(formData)
            .then(res => {
                toast.success("Password Updated Successfully");
                setApiLoading(false);
                reset()
            })
            .catch(err => {
                showErrMsg(err);
            })
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        }
        else if (err.data.message) {
            toast.error(err.data.message, { autoClose: 3000 });
        } else {
            toast.error("Something went wrong.Please try again later.", { autoClose: 3000 })
        }
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message
                });
            });
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="password-current">Current Password</label>
                                <input
                                    className={`form-control ${errors.old_password == null ? '' : 'is-invalid'}`}
                                    id="old_password" name="old_password" type="password" placeholder="Enter Old Password"
                                    defaultValue={""}
                                    ref={register({
                                        required: { value: true, message: "Current Password is required" },
                                    })}
                                />
                                {errors.old_password == null ? '' : <div className="invalid-feedback">{errors.old_password.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password-new">New Password</label>
                                <input
                                    className={`form-control ${errors.password == null ? '' : 'is-invalid'}`}
                                    id="password" name="password" type="password" placeholder="Enter New Password"
                                    defaultValue={""}
                                    ref={register({
                                        required: { value: true, message: "New Password is required" },
                                        minLength: { value: 8, message: "New password must be at least 8 characters" }
                                    })}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                {errors.password == null ? '' : <div className="invalid-feedback">{errors.password.message}</div>}

                            </div>
                            <div className="form-group">
                                <label htmlFor="password-confirm">Re-enter New Password</label>
                                <input
                                    className={`form-control ${errors.password_confirmation == null ? '' : 'is-invalid'}`}
                                    id="password_confirmation" name="password_confirmation" type="password" placeholder="Enter Confirm Password"
                                    ref={register({ required: true, minLength: 8, validate: validatePasswordMatch })}
                                />
                                {errors.password_confirmation && errors.password_confirmation.type === "validate" && (<div className="invalid-feedback"> Confirm Password and New Password entered does not match with each other</div>)}
                                {errors.password_confirmation && errors.password_confirmation.type === "required" && (<div className="invalid-feedback"> Confirm Password is required</div>)}
                                {errors.password_confirmation && errors.password_confirmation.type === "minLength" && (<div className="invalid-feedback"> Minimum length of password is 8</div>)}
                            </div>
                            <div className="form-group mt-5 mb-0">
                                <button type="submit" className="btn btn-primary">Change</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
