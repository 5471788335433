export default [
    {
      id: 1,
      name: "My Account",
      url: "/account/profile",
    },
    {
      id: 2,
      name: "Enquiry History",
      url: "/account/enquiry",
    },
]  