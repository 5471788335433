// react
import React, { useEffect, useRef, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';

import { connect } from "react-redux";
import { updateUser } from "../../store/user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockLoader from "../blocks/BlockLoader";
import { userTitleList } from '../../data/generalData';
import { useForm } from "react-hook-form";
import * as USER from "../../api/user";
import { getDefaultAvatar } from '../../services/utils';
import cleanFormData, { checkUnauthorized } from '../../helper/general-helper';
import { useHistory } from 'react-router';

export function AccountPageProfile(props) {
    const { updateUser: updateUser } = props;
    const inputFile = useRef(null);
    const history = useHistory();
    const { handleSubmit, register, errors, setError } = useForm();
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobilePrefix, setMobilePrefix] = useState("");
    const [mobilePrefixCountry, setMobilePrefixCountry] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [imageError, setImageError] = useState("");

    const defaultAvatar = getDefaultAvatar();
    const titleList = userTitleList();
    const mobileInputRef = useRef("");

    useEffect(() => {
        getMyProfile();
    }, [])

    const getMyProfile = (props) => {
        USER.getProfile()
            .then(res => {
                setUserData(res.data)
                setMobileNumber(res.data.mobile_prefix + res.data.mobile_number);
                setMobilePrefix(res.data.mobile_prefix ? res.data.mobile_prefix : "");
                setMobilePrefixCountry(res.data.mobile_country_code ? res.data.mobile_country_code : "");
                setProfileImageUrl(res.data.profile_image);
                updateUser({
                    title: res.data.title,
                    id: res.data.uuid,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    name: res.data.full_name,
                    // image: res.data.profile_image,
                    email: res.data.email,
                    mobilePrefix: res.data.mobile_prefix,
                    mobileCountry : res.data.mobile_country_code,
                    mobile: res.data.mobile_number,
                    role: res.data.roles,
                    profile_image: res.data.profile_image
                });
                setLoading(false);
            })
            .catch(err => { 
                setLoading(false); 
                if (!checkUnauthorized(err, history)) {
                    if (err.http_code == 422) {
                        toast.error(
                            "We have encountered an error processing your request. Please try again later.",
                            { autoClose: 3000 }
                        );
                    } else {
                        toast.error("Something went wrong Please try again later.");
                    }
                }
            })
    }

    if (loading) {
        return <BlockLoader />;
    }

    const handleMobileChange = (value, country) => {
        setMobileNumber(value);
        setMobilePrefix(country?.dialCode);
    };

    const handleImageChange = (e) => {
        e.preventDefault();

        if (e.target.files.length >= 1) {
            let reader = new FileReader();
            let file = e.target.files[0];

            if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png") {
                toast.error("Only accept file type with jpg,jpeg and png format", { autoClose: 3000 });
            }
            else if (file.size > 5000000) {
                toast.error("Maximum file size accepted is 5MB", { autoClose: 3000 });
            }
            else {
                reader.onloadend = () => {
                    setProfileImage(file);
                    setProfileImageUrl(reader.result);
                };
            }
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = (data) => {
        setApiLoading(true);
        setMobileError("");
        let valid = true;
        let mobile = mobileNumber != 0 ? mobileNumber.substring(1) : "";
        if (!mobilePrefix || !mobile || mobile.length == 0) {
            valid = false;
            setApiLoading(false);
            setMobileError("Mobile Number is required")
        }
        if (valid) {
            let formData = {
                ...data,
                profile_image: profileImage,
                mobile_number: mobile,
                mobile_number_country:
                    typeof parseInt(mobilePrefix) == "number"
                        ? (mobileInputRef.current?.state?.selectedCountry?.iso2 == undefined
                            ? mobilePrefixCountry
                            : mobileInputRef.current?.state?.selectedCountry?.iso2)
                        : mobilePrefix,
            }
            cleanFormData(formData);
            // console.log(formData);
            updateUserProfile(formData);
        }
    }

    const updateUserProfile = (data) => {
        USER.updateMyProfile(data)
            .then(res => {
                toast.success("User Profile updated successfully", { autoClose: 3000 });
                getMyProfile();
                setProfileImage(null);
                setApiLoading(false);
            })
            .catch(err => {
                showErrMsg(err);
            })
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        console.log(err);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        } else {
            toast.error("Something wrong.Please try again later.");
        }
        // else if (err.data.message) {
        //     toast.error(err.data.message);
        // } else {
        //     if (!checkUnauthorized(err, history)) {
        //         toast.error("Something wrong.Please try again later.");
        //     }
        // }
    };

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            if (key == 'mobile_number') {
                setMobileError(errors[errorKey].message)
            } else if (key == "profile_image") {
                setImageError(errors[errorKey].message)
            } else {
                return Object.keys(validationErrors).forEach((key) => {
                    setError(key, {
                        type: "server",
                        message: errors[errorKey].message
                    });
                });

            }
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Profile - ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>User Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row no-gutters">
                        <div className="col-12 col-md-6 profile-form">
                            <div className="form-group">
                                <label htmlFor="profile-title">Title</label>
                                <select className={`form-control ${errors.title == null ? '' : 'is-invalid'}`}
                                    name="title" ref={register({ required: true })} defaultValue={userData ? userData.title : ""}
                                >
                                    <option value="">Select Title</option>
                                    {titleList.map(title => (
                                        <option key={title.value} value={title.value}>
                                            {title.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.title == null ? '' : <div className="invalid-feedback">Title is required</div>}
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="profile-first-name">User Name</label>
                                <input
                                    className={`form-control ${errors.username == null ? '' : 'is-invalid'}`}
                                    id="username" name="username" type="text" placeholder="Enter User Name"
                                    defaultValue={userData ? userData.username : ""}
                                    ref={register({
                                        required: { value: true, message: "User Name is required" },
                                        maxLength: { value: 255, message: "User Name should not exceed 255 characters" },
                                    })}
                                />
                                {errors.username == null ? '' : <div className="invalid-feedback">{errors.username.message}</div>}
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="profile-first-name">First Name</label>
                                <input
                                    className={`form-control ${errors.first_name == null ? '' : 'is-invalid'}`}
                                    id="first_name" name="first_name" type="text" placeholder="Enter First Name"
                                    defaultValue={userData ? userData.first_name : ""}
                                    ref={register({
                                        required: { value: true, message: "First Name is required" },
                                        maxLength: { value: 100, message: "First Name should not exceed 100 characters" },
                                    })}
                                />
                                {errors.first_name == null ? '' : <div className="invalid-feedback">{errors.first_name.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-last-name">Last Name</label>
                                <input
                                    className={`form-control ${errors.last_name == null ? '' : 'is-invalid'}`}
                                    id="last_name" name="last_name" type="text" placeholder="Enter Last Name"
                                    defaultValue={userData ? userData.last_name : ""}
                                    ref={register({
                                        required: { value: true, message: "Last Name is required" },
                                        maxLength: { value: 100, message: "Last Name should not exceed 100 characters" },
                                    })}
                                />
                                {errors.last_name == null ? '' : <div className="invalid-feedback">{errors.last_name.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-email">Email</label>
                                <input
                                    className={`form-control ${errors.last_name == null ? '' : 'is-invalid'}`}
                                    id="email" name="email" type="text" placeholder="Enter Email"
                                    defaultValue={userData ? userData.email : ""}
                                    ref={register({
                                        required: { value: true, message: "Email is required" },
                                        maxLength: { value: 255, message: "Max length of an email address is 255 characters" },
                                        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email is invalid" }
                                    })}
                                />
                                {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="profile-mobile">Mobile Number</label>
                                <PhoneInput
                                    inputClass={"form-control"}
                                    value={mobileNumber ? mobileNumber : ""}
                                    // onlyCountries={["my", "sg"]}
                                    country={mobileNumber ? "" : "my"}
                                    onChange={(value, country) => handleMobileChange(value, country)}
                                    autoFormat={false}
                                    inputProps={{ required: true }}
                                    ref={mobileInputRef}
                                    preferredCountries={["my", "sg"]}
                                    countryCodeEditable={false}
                                />
                                {mobileError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{mobileError}</span></div>}
                            </div>

                        </div>
                        <div className="col-12 col-md-6 avatar-section">
                            <img onError={() => setProfileImageUrl(defaultAvatar)} alt="user-avatar" src={profileImageUrl ? profileImageUrl : defaultAvatar} className="profile-card__avatar large mb-1" onClick={() => inputFile.current.click()} alt="" />
                            <input className="fileInput" type="file" ref={inputFile} hidden accept="image/png,image/jpeg,image/jpg" onChange={(e) => handleImageChange(e)} />
                            <div className="text-center">
                                <small className="text-muted">File Size : Maximum 5 MB</small>
                                <br />
                                <small className="text-muted">File Extensions : .JPG, .JPEG, .PNG</small>
                                <br />
                                <button type="button" onClick={() => inputFile.current.click()} className="btn btn-sm btn-secondary mt-2 mb-4">
                                    Select Image
                                    </button>
                            </div>
                            {imageError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{imageError}</span></div>}
                        </div>
                    </div>
                    <div className="form-group mt-5 mb-0">
                        {apiLoading ? (
                            <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Save</button>)}
                    </div>
                </form>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    updateUser,
};

export default connect(null, mapDispatchToProps)(AccountPageProfile);
