// react
import React, { Component, useEffect } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";
import * as GENERAL from "../../api/general";
import BlockHeader from "../shared/BlockHeader";
// import { getCurrentProductType, url } from "../../services/utils";
// const bannerBgImg = "/images/wct_hero_bg.jpg";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 479,
            settings: {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ]
};

class BlockCustomerSlideShow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    departmentsAreaRef = null;

    media = window.matchMedia("(min-width: 992px)");

    componentDidMount() { }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    render() {
        const { locale, withDepartments, announcements } = this.props;
        const blockClasses = classNames("block-slideshow", {
            "block-slideshow--layout--full block--customer": !withDepartments,
            "block-slideshow--layout--with-departments block--customer ": withDepartments,
        });

        const layoutClasses = classNames("col-12", {
            "col-lg-12": !withDepartments,
            "col-lg-9": withDepartments,
        });

        const slides =
            announcements.length > 0 ? (
                announcements.map((slide, index) => {
                    return (
                        <a target="_blank" href={slide.link} key={index} className="block-slideshow__slide">
                            <div
                                className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                            >
                                <img
                                    src={slide.media_url}
                                    className="img-fluid avatar avatar-large mt-3 mt-sm-0 mr-2 ml-2 mb-3 customer-block"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div className="block-slideshow__slide-content">
                                {/* <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div
                            className="block-slideshow__slide-text"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        /> */}
                            </div>
                        </a>
                    );
                })
            ) : (
                <div className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(https://dreammarket.org/public/uploads/banner_img_no_available.png)`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(https://dreammarket.org/public/uploads/banner_img_no_available.png)`,
                        }}
                    />
                </div>
            );
        return announcements ? (
            <div className={blockClasses}
            //   style={getCurrentProductType().hash_id == 0 ? { backgroundImage: "url(" + bannerBgImg + ")" } : { backgroundColor: "#D0D0D6" }}
            >
                <div className="container banner-container">
                    <div className="row no-gutters">
                        {withDepartments && <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />}

                        <div className={layoutClasses}>
                            <div className="block-header__title d-flex justify-content-center pb-2" style={{ color: "#fff" }}>
                                <strong>{"Our Happy Customer"}</strong>
                            </div>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

BlockCustomerSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockCustomerSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockCustomerSlideShow);
