// react
import React, { useState, useEffect } from 'react';
import { carTransmission } from '../../data/generalData';

// data stubs
import specification from '../../data/shopProductSpec';
import * as GENERAL from '../../api/general';
import BlockLoader from '../blocks/BlockLoader';

function ProductTabSpecification(props) {
    const [product, setProduct] = useState(props.data);
    const [modelData, setModelData] = useState([]);
    const [loading, setLoading] = useState(true);
    const carTransmissionData = carTransmission();

    useEffect(() => {
        let params = { per_page: 'unlimited' }
        GENERAL.getAllModelList(params)
            .then(res => {
                setModelData(res.data.data)
                setLoading(false);
            })
            .catch(err => { setLoading(false); })
    }, [])

    const changeCapital = (value) => {
        if (value == 'model_uuid') {
            return value.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1))[0]
        } else {
            return value.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
        }
    }

    const findValue = (value, key) => {
        if (key == 'transmission') {
            return carTransmissionData.find(carT => carT.value == value).label
        } else if (key == 'model_uuid') {
            return modelData.find(model => model.uuid == value)?.name
        } else {
            return (value)
        }
    }

    if (loading) {
        return <BlockLoader />;
    }

    const sections = product.map((section, index) => {
        const features = (
            <div key={index} className="spec__row">
                <div className="spec__name">{changeCapital(section.key)}</div>
                <div className="spec__value">{findValue(section.value, section.key)}</div>
            </div>
        );

        return (
            <div key={index} className="spec__section">
                {/* <h4 className="spec__section-title">{section.name}</h4> */}
                {features}
            </div>
        );
    });


    return (
        <div className="spec">
            <h3 className="spec__header">Details</h3>
            {sections}
            {/* <div className="spec__disclaimer">
                Information on technical characteristics, the delivery set, the country of
                manufacture and the appearance of the goods is for reference only and is based on
                the latest information available at the time of publication.
            </div> */}
        </div>
    );
}

export default ProductTabSpecification;
