export function carTransmission() {
    const transmission = [
        // { value: "0", label: "All" }, 
        { value: "1", label: "Automatic" },{ value: "2", label: "Manual" }
    ]
    return transmission;
}

export function userTitleList() {
    const titleList = [
        { value: "mr", label: "Mr" }, { value: "mrs", label: "Mrs" },
        { value: "ms", label: "Ms" }, { value: "other", label: "Other" },
    ]
    return titleList;
}

export function PreSetbrandList() {
    const brandList = [
        { id: "1", name: "Alfa Romeo", image: "/images/CarBrand/brand-alfa-romeo.png" },
    ]
    return brandList;
}
