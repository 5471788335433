// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data stubs
import theme from '../../data/theme';

import cleanFormData from '../../helper/general-helper';
import * as AUTH from "../../api/auth";

export default function AccountPageLogin(props) {
    const history = useHistory();
    const { handleSubmit, register, errors, setError } = useForm();
    const [apiLoading, setApiLoading] = useState(false);
    const [loginError, setLoginError] = useState("");

    // if (AUTH.getAccessToken()) props.history.replace("/");

    const onSubmit = (data) => {
        setApiLoading(true);
        let formData = {
            ...data,
            portal_type: process.env.REACT_APP_PORTAL_TYPE,
        }
        cleanFormData(formData);
        // console.log(formData)
        userLogin(formData);
    }

    const userLogin = (data) => {
        AUTH.login(data)
            .then(res => {
                toast.success("Login Successful", { autoClose: 3000 });
                history.push("/");
            })
            .catch(err => {
                showErrMsg(err)
            })
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        }
        else if (err.data.message) {
            toast.error(err.data.message, { autoClose: 3000 });
        } else if (err.http_code == 403 && err.data?.errors) {
            for (const errorKey in err.data?.errors) {
                toast.error(err.data?.errors[errorKey].message)
            }
        } else {
            toast.error("Something went wrong.Please try again later.", { autoClose: 3000 })
        }
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message
                });
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <div className="block" style={{ paddingTop: '46px' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-10 mb-1 sign-up-col">
                            <span>
                                Don't have an account?<Link to="/register"> Register Here</Link>
                            </span>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Login</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="register-email">Email address <span className="text-danger">*</span></label>
                                            <input
                                                className={`form-control ${errors.email == null ? '' : 'is-invalid'}`}
                                                id="email" name="email" type="text" placeholder="Enter Email Address"
                                                ref={register({
                                                    required: { value: true, message: "Email is required" },
                                                    maxLength: { value: 255, message: "Email should not exceed 255 characters" },
                                                    minLength: { value: 2, message: "Email should more than 2 characters" },
                                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                                                })}
                                            />
                                            {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">Password <span className="text-danger">*</span></label>
                                            <input
                                                className={`form-control ${errors.password == null ? '' : 'is-invalid'}`}
                                                id="password" name="password" type="password" placeholder="Enter Password"
                                                ref={register({
                                                    required: { value: true, message: "Password is required" },
                                                    minLength: { value: 8, message: "Password should more than 8 characters" },
                                                })}
                                            />
                                            <small className="form-text text-muted float-right">
                                                <Link to="/forgot-password">Forgot Password?</Link>
                                            </small>
                                            {errors.password == null ? '' : <div className="invalid-feedback">{errors.password.message}</div>}
                                        </div>
                                        {apiLoading ? (
                                            <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4 mobile-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                            </button>
                                        ) : (
                                            <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4 mobile-btn" onClick={handleSubmit(onSubmit)}>
                                                Login
                                            </button>)}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0 justify-content-center d-md-down-none">
                                <div className="card-body card-body-register">
                                    <h3 className="card-title">Don't Have An Account? Register Here!</h3>
                                    <div className="justify-around">
                                        <Link to="/register" className="btn btn-primary mr-3">
                                            Register
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
