// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DataTable from 'react-data-table-component'
import '../../scss/react-dataTable-component.scss'

// data stubs
import theme from '../../data/theme';

import * as USER from "../../api/user";
import moment from "moment";
import BlockLoader from '../blocks/BlockLoader';
import { checkUnauthorized } from '../../helper/general-helper';
import { toast } from 'react-toastify';

export default function AccountPageEnquiry(props) {
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [enquiryData, setEnquiryData] = useState([]);
    const [sortType, setSortType] = useState("");
    const [sortBy, setSortBy] = useState("");

    let params = {};
    const basicColumns = [
        {
            name: "#",
            selector: "index",
            maxWidth: "100px",
        },
        {
            name: "Enquiry Type",
            selector: "enquiry_type.name",
            sortable: true,
            maxWidth: "200px",
            // cell: row => <div>{row.enquiry_type == 0 ? "Buy Car" : "Sell Car"}</div>,
        },
        {
            name: "Status",
            selector: "status.name",
            maxWidth: "200px",
            sortable: true,
        },
        {
            name: "Create Date",
            selector: "created_at",
            sortable: true,
            maxWidth: "250px",
            cell: row => <div>{moment(row.created_at).format("YYYY-MM-DD hh:mm A")}</div>
        },
        {
            name: 'Action',
            minWidth: '100px',
            center:true,
            cell: row => <div style={{ cursor: 'pointer' }}><button className="btn btn-xs btn-secondary" onClick={() => goToDetail(row)}>View</button></div>
          }
    ]

    useEffect(() => {
        getMyEnquiry(params)
    }, [perPage, page, sortBy, sortType])

    const getMyEnquiry = (params) => {
        setLoading(true);
        params.per_page = perPage;
        params.page = page;
        params.sort_by = sortBy;
        params.sort_type = sortType;
        USER.getEnquiry(params)
            .then(res => {
                let order = 1;
                let data = res.data.data.map((enquiry) => {
                    enquiry.index = order++
                    return enquiry
                })
                setEnquiryData(data);
                setTotalRows(res.data.total);
                setLoading(false);
            })
            .catch(err => { 
                setLoading(false); 
                if (!checkUnauthorized(err, history)) {
                    if (err.http_code == 422) {
                        toast.error(
                            "We have encountered an error processing your request. Please try again later.",
                            { autoClose: 3000 }
                        );
                    } else {
                        toast.error("Something went wrong Please try again later.");
                    }
                }
            })
    }

    const handlePageChange = (page) => {
        setPage(page)
    };

    // pagination change row per page
    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    // table sort
    const handleSort = (column, sortDirection) => {
        setSortBy(column.selector);
        setSortType(sortDirection);
    }

    const goToDetail = (row) => {
        history.push({
            pathname: "/account/enquiry/" + row.id,
            state: { data: row }
        })
    }

    if (loading) {
        return <BlockLoader />;
    }

    return (
        <div className="card">
            <Helmet>
                <title>{`Enquiry History — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Enquiry History</h5>
            </div>
            <div className="card-divider" />
            <div className="card-table">
                <DataTable
                    noHeader
                    responsive={true}
                    pagination
                    onSort={handleSort}
                    pointerOnHover={true}
                    onRowClicked={(row) => goToDetail(row)}
                    paginationServer
                    onChangePage={handlePageChange}
                    paginationDefaultPage={page}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    data={enquiryData}
                    columns={basicColumns}
                    className='react-dataTable'
                    // sortIcon={<ChevronDown size={10} />}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                />

                {/* <div className="table-responsive-sm">
                    <table>
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList}
                        </tbody>
                    </table>
                </div> */}
            </div>
            <div className="card-divider" />
            {/* <div className="card-footer">
                <Pagination current={page} total={3} onPageChange={handlePageChange} />
            </div> */}
        </div>
    );
}
