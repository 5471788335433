// react
import moment from 'moment';
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
// import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';


// data stubs
import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '/site/about-us' },
        { title: 'Business Partner Details', url: '/site/business-partner' },
        // { title: 'Privacy Policy', url: '' },
        // { title: 'Brands', url: '' },
        // { title: 'Contact Us', url: '' },
        // { title: 'Returns', url: '' },
        // { title: 'Site Map', url: '' },
    ];

    // const accountLinks = [
    //     { title: 'Store Location', url: '' },
    //     { title: 'Order History', url: '' },
    //     { title: 'Wish List', url: '' },
    //     { title: 'Newsletter', url: '' },
    //     { title: 'Specials', url: '' },
    //     { title: 'Gift Certificates', url: '' },
    //     { title: 'Affiliate', url: '' },
    // ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        {/* <div className="col-6 col-md-3 col-lg-4">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div> */}
                        {/* <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div> */}
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Copyright <i className="far fa-copyright" aria-hidden="true" /> {moment().format("YYYY")} DuitCar.my . All Rights Reserved.
                    </div>
                    {/* <div className="site-footer__social-icons mt-2">
                        <ul className="social-icons-list row mb-0">
                            <li className="social-icons mt-2">
                                <a href={theme.social.facebook_url} target="_blank">
                                    <i
                                        className="fab fa-facebook-f social-icon"
                                        aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>

            {/* <ToTop whatsappNumber={theme.whatsapp.contact} /> */}
        </div>
    );
}
