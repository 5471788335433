// react
import React, { useEffect, useMemo, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
// import shopApi from '../../api/shop';
// import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks
// import BlockBanner from '../blocks/BlockBanner';
// import BlockBrands from '../blocks/BlockBrands';
import BlockCarType from '../blocks/BlockCarType';
// import BlockFeatures from '../blocks/BlockFeatures';
// import BlockPosts from '../blocks/BlockPosts';
// import BlockProductColumns from '../blocks/BlockProductColumns';
// import BlockProducts from '../blocks/BlockProducts';
import BlockCarBrandCarousel from '../blocks/BlockCarBrandCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockCustomerSlideShow from '../blocks/BlockCustomerSlideShow';

// data stubs
// import posts from '../../data/blogPosts';
import theme from '../../data/theme';
// import { getNoImg } from '../../services/utils';
import * as GENERAL from "../../api/general";

const carBrandImgList = [
    { id: 0, name: "All Brand", image: "", slug: "all-brand" }
];
function HomePage() {

    const [popularBrand, setPopularBrand] = useState([]);
    const [bannerAnnouncements, setBannerAnnouncements] = useState([]);

    useEffect(() => {
        let params = {
            per_page: 'unlimited',
            is_popular: 1,
            sort_by: 'name',
            sort_type: 'asc'
        }
        GENERAL.getPopularBrandList(params)
            .then(res => {
                let list = res.data;
                list.data = [...list.data, ...carBrandImgList];
                setPopularBrand(list.data)
            })
            .catch(err => { })

        GENERAL.getSystemSetting()
            .then(res => {
                setBannerAnnouncements(res.data.store?.home_section_cms?.first)
            })
            .catch(err => {})
    }, [])
    
    /**
     * Product columns.
     */
    // const columns = useProductColumns(
    //     useMemo(() => [
    //         {
    //             title: 'Top Rated Products',
    //             source: () => shopApi.getTopRatedProducts({ limit: 3 }),
    //         },
    //         {
    //             title: 'Special Offers',
    //             source: () => shopApi.getDiscountedProducts({ limit: 3 }),
    //         },
    //         {
    //             title: 'Bestsellers',
    //             source: () => shopApi.getPopularProducts({ limit: 3 }),
    //         },
    //     ], []),
    // );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
                <meta name="description" content="Duitcar is latest online platform for End-user to sell used car & browse used vehicle." />
                <meta name="og:description" content="Duitcar is latest online platform for End-user to sell used car & browse used vehicle." />
            </Helmet>

            {useMemo(() => <BlockSlideShow withDepartments />, [])}

            {/* {useMemo(() => <BlockFeatures />, [])} */}

            {/* {useMemo(() => (
                <BlockProductsCarousel
                    title="Featured Products"
                    layout="grid-4"
                    products={featuredProducts.data}
                    loading={featuredProducts.isLoading}
                    groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            ), [featuredProducts])} */}
            <BlockCarBrandCarousel layout="grid-5" carBrandImgList={popularBrand} />

            {/* {useMemo(() => <BlockBanner />, [])} */}

            {/* {useMemo(() => (
                <BlockProducts
                    title="Bestsellers"
                    layout="large-first"
                    featuredProduct={bestsellers.data[0]}
                    products={bestsellers.data.slice(1, 7)}
                />
            ), [bestsellers.data])} */}

            <BlockCarType
                title="Shop Car By Type"
                layout="classic"
            />

            {bannerAnnouncements ? <BlockCustomerSlideShow announcements={bannerAnnouncements} /> : null }

            {/* {useMemo(() => (
                <BlockProductsCarousel
                    title="New Arrivals"
                    layout="horizontal"
                    rows={2}
                    products={latestProducts.data}
                    loading={latestProducts.isLoading}
                    groups={latestProducts.tabs}
                    onGroupClick={latestProducts.handleTabChange}
                />
            ), [latestProducts])} */}

            {/* {useMemo(() => <BlockPosts title="Latest News" layout="list-sm" posts={posts} />, [])} */}

            {/* {useMemo(() => <BlockBrands />, [])} */}

            {/* {useMemo(() => <BlockProductColumns columns={columns} />, [columns])} */}
        </React.Fragment>
    );
}

export default HomePage;
