import * as HELPER from "../helper/general-helper";
const axios = require("axios");

let searchRejectRequest = null;
export async function getProductList(params) {
  // cancel previous ajax
  if (searchRejectRequest) {
    searchRejectRequest.cancel();
  }
  // creates a new token for upcomming ajax (overwrite the previous one)
  searchRejectRequest = axios.CancelToken.source();

  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/product" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        // cancelToken: searchRejectRequest.token
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getProductDetails(slug) {
  let url = process.env.REACT_APP_HOST + "/" + process.env.REACT_APP_API_PREFIX + "/product/" + slug;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        // headers: authHeader(),
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getProductCategoryList(params) {

  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/product-category" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}