export const url = {
    home: () => '/',

    catalog: () => '/shop/catalog',

    category: (category) => `/shop/catalog/${category.slug}`,

    product: (product) => `/car/${product.slug}`,

    brand: (brand) => `/brand/${brand.brand_info?.slug ? brand.brand_info.slug : brand.slug}`
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

export function getNoImg() {
    return "/images/no_image.png";
}

export function noImgSmall() {
    return "/images/img_no_image_276px.png"
}

export function getDefaultAvatar() {
    return "/images/avatars/defaultAvatar.jpg";
}

export function allEqual(arr) {
    return new Set(arr).size == 1;
}