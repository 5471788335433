// react
import React from 'react';

export default function BlockLoader() {
    return (
        <div className="block-loader">
            <div className="block-loader__spinner" />
        </div>
    );
}
