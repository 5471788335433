import * as HELPER from "../helper/general-helper";
const axios = require("axios");

function getExpireDate(expireInSeconds) {
    const now = new Date()
    const expireTime = new Date(now)
    expireTime.setSeconds(now.getSeconds() + expireInSeconds)
    return expireTime
}

export async function login(data) {
    // let header = await authHeader();
    // header["Content-Type"]= "multipart/form-data"
    // header["Access-Control-Allow-Origin"]= "*"

    var formData = HELPER.appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_HOST + "/"
                + process.env.REACT_APP_API_PREFIX +
                "/login", formData,
                {
                    timeout: 60000,
                    //   headers: header
                })
            .then(response => {
                const { data } = response;
                const token = data.data.access_token
                const expiryDate = getExpireDate(data.data.expires_in)
                const tokenData = {
                    access_token: token,
                    expiredAt: expiryDate
                }
                localStorage.setItem('access_token', JSON.stringify(tokenData))
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({
                            http_code: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            })
            .finally();
    });
}

export async function getAccessToken() {
    let token
    if (JSON.parse(localStorage.getItem('access_token'))) {
        token = JSON.parse(localStorage.getItem('access_token'));
    }
    if (token) {
        return token;
    } else {
        return 0;
    }
}

export async function logout(history) {
    localStorage.removeItem('access_token')
    // history.replace("/");
    if (history) history.replace("/");
}

export function removeUserFromStorage() {
    localStorage.removeItem("access_token");
}