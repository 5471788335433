// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// application
import BlockHeader from '../shared/BlockHeader';
import CarTypeCard from '../shared/CarTypeCard';

import * as PRODUCT from '../../api/product';
export default function BlockCarType(props) {
    const { title, layout } = props;
    const [carType, setCarType] = useState([]);

    useEffect(() => {
        let params = {
            sort_type : 'asc',
            appends : "image"
        }
        PRODUCT.getProductCategoryList(params)
            .then(res => {
                setCarType(res.data.data)
            })
    }, [])

    let smalls;

    const typeList = carType.map((ctype, index) => (
        <div key={index} className="block-car__list-item">
            <CarTypeCard type={ctype} />
        </div>
    ));

    smalls = <div className="block-products__list mb-4">{typeList}</div>;

    return (
        <div className={`block block--highlighted block-products block-products--layout--${layout} mb-0`}>
            <div className="container">
                <BlockHeader title={title} />
                <div>{smalls}</div>
            </div>
        </div>
    );
}

BlockCarType.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.array,
    layout: PropTypes.oneOf(['classic', 'compact']),
};

BlockCarType.defaultProps = {
    cartype: [],
    layout: 'classic',
};
