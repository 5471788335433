// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { getNoImg, url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';
function BrandCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('brand-card border', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];

    if (product.is_popular == true) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Popular</div>);
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    let image;

    image = (
        <div className="brand-card__image">
            <Link to={{
                pathname: `/brand/${product.slug}`,
                state: { brand: product.name }
            }}>
                <img className="brand-image__img" src={product.image ? product.image.url : getNoImg()} alt="" />
            </Link>
        </div>
    );

    // if (product.attributes && product.attributes.length) {
    //     features = (
    //         <ul className="product-card__features-list">
    //             {product.attributes.filter((x) => x.featured).map((attribute, index) => (
    //                 <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
    //             ))}
    //         </ul>
    //     );
    // }

    return (
        <div className={containerClasses}>
            {/* {badges} */}
            {image}
            <div className="brand-card__info">
                <div className="brand-card__name">
                    <Link to={"/"}>{product.name}</Link>
                </div>
            </div>
        </div>
    );
}

BrandCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrandCard);
