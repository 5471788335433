// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";

import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
import cleanFormData from '../../helper/general-helper';
import * as USER from '../../api/user';
import { toast } from 'react-toastify';

function SitePageResetPassword(props) {
    const { register, errors, handleSubmit, setError, reset } = useForm(); /* register is a function */
    const [apiLoading, setApiLoading] = useState(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const [confirmPassword, setConfirmPassword] = useState("");
    const email = params.get("email");
    const token = params.get("token");

    const validatePasswordMatch = (newpass) => {
        return confirmPassword === newpass;
    };

    const onSubmit = (data) => {
        setApiLoading(true);
        let formData = {
            ...data,
            email: email,
            token: token,
            portal_type: process.env.REACT_APP_PORTAL_TYPE,
        }
        cleanFormData(formData);
        console.log(formData)
        USER.resetPassword(formData)
            .then(res => {
                toast.success("Password reset successfully");
                props.history.replace("/login");
                reset({
                    old_password: '',
                    password: '',
                    password_confirmation: ''
                });
            })
            .catch(err => {
                setApiLoading(false)
                reset({
                    old_password: '',
                    password: '',
                    password_confirmation: ''
                });
                let validationErrors = {}
                if (err.http_code == 422 && err.data.errors) {
                    for (const errorKey in err.data.errors) {
                        let key = errorKey.split(".")[0];
                        if (!Object.keys(validationErrors).includes(key)) {
                            validationErrors[key] = "";
                        }
                        validationErrors[key] += ", " + err.data.errors[errorKey].message;

                        if (key == 'token') {
                            toast.error("Reset password token is expired. Please send new reset request.");
                            props.history.replace("/forgot-password");
                        } else {
                            return Object.keys(validationErrors).forEach((key) => {
                                setError(key, {
                                    type: "server",
                                    message: err.data.errors[errorKey].message
                                });
                            });
                        }
                    }
                } else {
                    toast.error("The system has encountered an unknown error.Please try again later.")
                }
            })
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message
                });
            });
        }
    };

    return (
        <div className="block container">
            <Helmet>
                <title>{`Reset Password — ${theme.name}`}</title>
            </Helmet>
            <div className="row justify-content-center">
                <div className="mt-5 col-12 col-md-10">
                    <div className="card flex-grow-1 p-4">
                        <div className="card-body">
                            <h1 className="not-found__title">Reset Your Password</h1>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="password-new">New Password</label>
                                            <input
                                                className={`form-control ${errors.password == null ? '' : 'is-invalid'}`}
                                                id="password" name="password" type="password" placeholder="Enter New Password"
                                                ref={register({
                                                    minLength: { value: 8, message: "New Password must be at least 8 characters" },
                                                    required: { value: true, message: "New Password is required" },
                                                })}
                                                onChange={(e) => { setConfirmPassword(e.target.value) }}
                                            />
                                            {errors.password == null ? '' : <div className="invalid-feedback">{errors.password.message}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="password-new">Re-enter New Password</label>
                                            <input className={`form-control ${errors.password_confirmation == null ? '' : 'is-invalid'}`}
                                                ref={register({ required: true, minLength: 8, validate: validatePasswordMatch })}
                                                name="password_confirmation" type="password" defaultValue={""} placeholder="Re-enter New Password" />
                                            {errors.password_confirmation && errors.password_confirmation.type === "validate" && (<div className="invalid-feedback"> Confirm Password and New Password entered does not match with each other</div>)}
                                            {errors.password_confirmation && errors.password_confirmation.type === "required" && (<div className="invalid-feedback"> This field is required</div>)}
                                            {errors.password_confirmation && errors.password_confirmation.type === "minLength" && (<div className="invalid-feedback"> Minimum length of password is 8</div>)}
                                        </div>
                                    </div>
                                </div>
                                {apiLoading ? (
                                    <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                        Submit
                                    </button>)}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageResetPassword;
