// react
import React, { useState } from 'react';
import { useHistory } from 'react-router';

// third-party
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data stubs
import theme from '../../data/theme';

import cleanFormData from '../../helper/general-helper';
import { userTitleList } from '../../data/generalData';
import * as USER from "../../api/user";
import { Link } from 'react-router-dom';

export default function AccountPageRegister() {
    const history = useHistory();
    const { handleSubmit, register, errors, setError } = useForm();
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobilePrefix, setMobilePrefix] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [password, setPassword] = useState("");
    const [apiLoading, setApiLoading] = useState(false);
    const [agreeTnC, setAgreeTnC] = useState(0);

    const titleList = userTitleList();

    const handleMobileChange = (value, country) => {
        setMobileNumber(value);
        setMobilePrefix(country.countryCode);
    };

    const validatePasswordMatch = (newpass) => {
        return password === newpass;
    }

    const onSubmit = (data) => {
        setApiLoading(true);
        setMobileError("");
        let valid = true;
        let mobile = mobileNumber != 0 ? mobileNumber.substring(1) : "";
        if (!mobilePrefix || !mobile || mobile.length == 0) {
            valid = false;
            setApiLoading(false);
            setMobileError("Mobile Number is required")
        }
        if (valid) {
            let formData = {
                ...data,
                mobile_number: mobile,
                mobile_number_country: mobilePrefix,
                accept_tnc: agreeTnC,
            }
            cleanFormData(formData);
            // console.log(formData);
            registerUser(formData);
        }
    }

    const registerUser = (data) => {
        USER.registerMember(data)
            .then(res => {
                history.push("/login");
                toast.success("Sign Up Successful!", { autoClose: 3000 });
            })
            .catch(err => {
                showErrMsg(err);
            })
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        }
        else if (err.data.message) {
            toast.error(err.data.message, { autoClose: 3000 });
        } else {
            toast.error("Something went wrong.Please try again later.", { autoClose: 3000 })
        }
    }

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            if (key == 'mobile_number') {
                setMobileError(errors[errorKey].message)
            } else {
                return Object.keys(validationErrors).forEach((key) => {
                    setError(key, {
                        type: "server",
                        message: errors[errorKey].message
                    });
                });

            }
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Register — ${theme.name}`}</title>
            </Helmet>

            <div className="block" style={{ paddingTop: "46px" }}>
                <div className="container">
                    <span>Already have account? <Link to="/login">Login Here</Link></span>
                    <div className="row">
                        <div className="col-md-12 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register Your Account</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-row">
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-username">UserName</label>
                                                    <input
                                                        className={`form-control ${errors.username == null ? '' : 'is-invalid'}`}
                                                        id="username" name="username" type="text" placeholder="Enter Username"
                                                        ref={register({
                                                            // required: { value: true, message: "First Name is required" },
                                                            maxLength: { value: 255, message: "Username should not exceed 255 characters" },
                                                        })}
                                                    />
                                                    {errors.username == null ? '' : <div className="invalid-feedback">{errors.username.message}</div>}
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-first-name">First Name <span className="text-danger">*</span></label>
                                                    <input
                                                        className={`form-control ${errors.first_name == null ? '' : 'is-invalid'}`}
                                                        id="first_name" name="first_name" type="text" placeholder="Enter First Name"
                                                        ref={register({
                                                            required: { value: true, message: "First Name is required" },
                                                            maxLength: { value: 100, message: "First Name should not exceed 100 characters" },
                                                        })}
                                                    />
                                                    {errors.first_name == null ? '' : <div className="invalid-feedback">{errors.first_name.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-last-name">Last Name <span className="text-danger">*</span></label>
                                                    <input
                                                        className={`form-control ${errors.last_name == null ? '' : 'is-invalid'}`}
                                                        id="last_name" name="last_name" type="text" placeholder="Enter Last Name"
                                                        ref={register({
                                                            required: { value: true, message: "Last Name is required" },
                                                            maxLength: { value: 100, message: "Last Name should not exceed 100 characters" },
                                                        })}
                                                    />
                                                    {errors.last_name == null ? '' : <div className="invalid-feedback">{errors.last_name.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-email">Email address <span className="text-danger">*</span></label>
                                                    <input
                                                        className={`form-control ${errors.email == null ? '' : 'is-invalid'}`}
                                                        id="email" name="email" type="text" placeholder="Enter Email Address"
                                                        ref={register({
                                                            required: { value: true, message: "Email is required" },
                                                            maxLength: { value: 255, message: "Email should not exceed 255 characters" },
                                                            minLength: { value: 2, message: "Email should more than 2 characters" },
                                                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                                                        })}
                                                    />
                                                    {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-title">Title <span className="text-danger">*</span></label>
                                                    <select className={`form-control ${errors.title == null ? '' : 'is-invalid'}`}
                                                        name="title" ref={register({ required: true })}>
                                                        <option value="">Select Title</option>
                                                        {titleList.map(title => (
                                                            <option key={title.value} value={title.value}>
                                                                {title.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.title == null ? '' : <div className="invalid-feedback">Title is required</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-password">Password <span className="text-danger">*</span></label>
                                                    <input
                                                        className={`form-control ${errors.password == null ? '' : 'is-invalid'}`}
                                                        id="password" name="password" type="password" placeholder="Enter Password"
                                                        ref={register({
                                                            required: { value: true, message: "Password is required" },
                                                            minLength: { value: 8, message: "Password should more than 8 characters" },
                                                        })}
                                                        onChange={(e) => { setPassword(e.target.value) }}
                                                    />
                                                    {errors.password == null ? '' : <div className="invalid-feedback">{errors.password.message}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-password">Confirm Password <span className="text-danger">*</span></label>
                                                    <input
                                                        className={`form-control ${errors.password_confirmation == null ? '' : 'is-invalid'}`}
                                                        id="password_confirmation" name="password_confirmation" type="password" placeholder="Enter Confirm Password"
                                                        ref={register({ required: true, minLength: 8, validate: validatePasswordMatch })}
                                                    />
                                                    {errors.password_confirmation && errors.password_confirmation.type === "validate" && (<div className="invalid-feedback"> Confirm Password and New Password entered does not match with each other</div>)}
                                                    {errors.password_confirmation && errors.password_confirmation.type === "required" && (<div className="invalid-feedback"> Confirm Password is required</div>)}
                                                    {errors.password_confirmation && errors.password_confirmation.type === "minLength" && (<div className="invalid-feedback"> Minimum length of password is 8</div>)}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="register-mobile">Mobile Number <span className="text-danger">*</span></label>
                                                    <PhoneInput
                                                        inputClass={"form-control"}
                                                        value={mobileNumber ? mobileNumber : ""}
                                                        onlyCountries={["my", "sg"]}
                                                        country={mobileNumber ? "" : "my"}
                                                        onChange={(value, country) => handleMobileChange(value, country)}
                                                        autoFormat={false}
                                                        inputProps={{ required: true }}
                                                        // preferredCountries={["my", "sg"]}
                                                        countryCodeEditable={false}
                                                    />
                                                    {mobileError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{mobileError}</span></div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <input className="mr-2" type="checkbox" onChange={() => setAgreeTnC(agreeTnC == 0 ? 1 : 0)} />
                                                I've read and accept the{" "}
                                            <span className="link-button"
                                            // onClick={() => setOpenTnC(true)}
                                            >
                                                terms & conditions
                                            </span>
                                            .
                                        </div>
                                        {apiLoading ? (
                                            <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                            </button>
                                        ) : (
                                            <button type="submit" disabled={!agreeTnC} className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                Register
                                            </button>)}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
