export default {
    name: 'DuitCar',
    description : 'Duitcar is latest online platform for End-user to sell used car & browse used vehicle.',
    fullName: 'DuitCar',
    url: '',
    contacts: {
        address: 'Level 3, Multilevel Carpark, Jalan Equine 10E, 43300 Seri Kembangan, Selangor',
        buy_email: 'buycar@duitcar.my (Buy Car)',
        sell_email : 'sellcar@duitcar.my (Sell Car)',
        buy_phone: '0111-2232226 (Buy Car)',
        sell_phone : '014-5011111 (Sell Car)'
    },
    whatsapp : {
        // contact : '+6016-7752935',
        // buy : '+6016-7752935',
        // sell : '+6016-7752935'
        contact : '+6014-5011111',
        buy : '+60111-2232226',
        sell : '+6014-5011111'
    },
    about: {
        info : "Duitcar is latest online platform for End-user to sell used car & browse used vehicle . Our platform able let customer to customize their desire vehicle from body colour , rim design , bodykit add on and more. We offer higher trade-in value for your used vehicle . We offer high quality of used car vehicle with more than hundred of unit to browse. We offer arrangement of HP Loan , Insurance Renewal or Cash Purchase",
    },
    operation : {
        time : "Mon-Sun 10:00am - 6:00pm"
    },
    social : {
        facebook_url : "https://www.facebook.com/DuitCarMy-110288494488553"
    }
};
