// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
    useHistory,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import * as AUTH from "../../api/auth";
import { toast } from "react-toastify";

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';
import AccountPageEnquiry from './AccountPageEnquiry';
import AccountPageEnquiryDetails from './AccountPageEnquiryDetails';

export default function AccountLayout(props) {
    const { match, location } = props;
    const history = useHistory();
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    const links = [
        // { title: 'Dashboard', url: 'dashboard' },
        { title: 'User Profile', url: 'profile' },
        { title: 'Change Password', url: 'password' },
        { title: 'Enquiry History', url: 'enquiry' },
        // { title: 'Order History', url: 'orders' },
        // { title: 'Order Details', url: 'orders/5' },
        // { title: 'Addresses', url: 'addresses' },
        // { title: 'Edit Address', url: 'addresses/5' },
        // { title: 'Logout', url: 'login' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    const handleLogout = () => {
        toast.info("Logout Successful", { autoClose: 2000 });
        AUTH.logout(history);
    };


    if (!AUTH.getAccessToken()) {
        return <Redirect to={`/`} />;
    }

    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navigation</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                {/* <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} /> */}
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                                <Route exact path={`${match.path}/enquiry`} component={AccountPageEnquiry} />
                                <Route exact path={`${match.path}/enquiry/:enquiryid`} component={AccountPageEnquiryDetails} />
                                {/* <Route exact path={`${match.path}/orders`} component={AccountPageOrders} /> */}
                                {/* <Route exact path={`${match.path}/orders/:orderId`} component={AccountPageOrderDetails} /> */}
                                {/* <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} /> */}
                                {/* <Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} /> */}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
