// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';

const HeroBanner = "/images/banners/duitcar_hero_image01.jpg"
class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    slides = [
        {
            title: 'Great Deals, Great Saving',
            text: 'Buy and sell used car in one-stop platform',
            image_classic: {
                // ltr: 'images/slides/slide-1-ltr.jpg',
                rtl: 'images/slides/slide-1-rtl.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-1-full-ltr.jpg',
                rtl: 'images/slides/slide-1-full-rtl.jpg',
            },
            image_mobile: {
                // ltr: 'images/slides/slide-1-mobile.jpg',
                rtl: 'images/slides/slide-1-mobile.jpg',
            },
        },
    ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-12': withDepartments,
            },
        );

        const slides = this.slides.map((slide, index) => {
            const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_mobile[direction]})`,
                        }}
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="block-slideshow__slide-content homepage-banner" style={{ textAlign: "center", height: "100%", width: "100%", paddingTop: "70px" }}>
                            <div
                                className="block-slideshow__slide-title"
                            // dangerouslySetInnerHTML={{ __html: slide.title }}
                            ><FormattedMessage id="banner.title" defaultMessage={slide.title} /></div>
                            <div
                                className="block-slideshow__slide-text"
                            // dangerouslySetInnerHTML={{ __html: slide.text }}
                            ><FormattedMessage id="banner.sub" defaultMessage={slide.text} /></div>
                            <div className="block-slideshow__slide-button">
                                <div className="button-div">
                                    <Link to="/buy-car" className="btn btn-primary btn-lg btn-buy-car"><FormattedMessage id="banner.buttonBuy" defaultMessage="Buy A Car" /></Link>
                                    <span className="d-none d-sm-block" style={{ display: "flex", paddingTop: "0.375rem" }}><FormattedMessage id="banner.or" defaultMessage="or" /></span>
                                    <Link to="/sell-car" className="btn btn-sell-car btn-lg"><FormattedMessage id="banner.buttonSell" defaultMessage="Sell A Car" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses} style={{ backgroundImage: "url(" + HeroBanner + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "bottom" }}>
                {/* <div className={blockClasses} style={{ backgroundColor: '#F3F6F8', opacity: "0.75" }}>   */}
                <div className="container banner-container">
                    <div className="row no-gutters">

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                {slides}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
