import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as GENERAL from "../../api/general";
import BlockLoader from "../blocks/BlockLoader";
import theme from '../../data/theme';
import { getDefaultAvatar } from "../../services/utils";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from 'react-redux';
import cleanFormData from "../../helper/general-helper";
import { useHistory } from "react-router-dom";

export default function BookingForm(props) {
    const history = useHistory();
    const { handleSubmit, register, errors, setError } = useForm();
    const [apiLoading, setApiLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState(props.data);
    const [modelData, setModelData] = useState(props.model);
    const [bookingNo, setBookingNo] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobilePrefix, setMobilePrefix] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [whatsAppDisabled, setWhatsAppDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const login_name = useSelector(state => state?.user?.profile?.name)
    const login_email = useSelector(state => state?.user?.profile?.email)
    const login_prefix = useSelector(state => state?.user?.profile?.mobilePrefix)
    const login_mobile = useSelector(state => state?.user?.profile?.mobile)
    const token = localStorage.getItem("access_token");
    const mobileInputRef = useRef("");

    useEffect(() => {
        if (token) {
            setMobileNumber(login_prefix && login_mobile ? login_prefix + login_mobile : "");
            setMobilePrefix(login_prefix ? login_prefix : "");
        }
        if (productData != null) {
            if (productData.specification != null) {
                for (const [key, values] of Object.entries(productData.specification)) {
                    productData[values.key] = values.value
                }
            }
            if (productData.creator_info && (productData.creator_info != {} && productData.creator_info != undefined)) {
                setBookingNo(productData.creator_info?.contact);
            }
            setLoading(false)
        } else setLoading(false)
    }, [])

    useEffect(() => {
        if (name != "" && email != "" && mobileNumber != "") {
            if (mobileNumber.length > 5) {
                setWhatsAppDisabled(false)
            } else {
                setWhatsAppDisabled(true)
            }
        }
    }, [name, email, mobileNumber])

    useEffect(() => {
        if (modelData.length > 0 && productData.length > 0) {
            getPresetMessage();
        }
    }, [modelData, productData])

    if (loading) {
        return <BlockLoader />;
    }

    const getPresetMessage = () => {
        let presetMessage = "";
        presetMessage = "I would like to enquiry for this model."
        presetMessage += `\r\nBrand : ${productData.brand_info?.name}`
        presetMessage += `\r\nModel : ${modelData.find(model => model.uuid == productData.model_uuid)?.name}`
        presetMessage += `\r\nManufactured Year : ${productData.manufactured_year}`
        presetMessage += `\r\nCategory : ${productData.product_category_info?.name}`
        presetMessage += `\r\n${window.location.href}`
        return encodeURI(presetMessage)
    }

    const handleMobileChange = (value, country) => {
        setMobileNumber(value);
        setMobilePrefix(country.countryCode);
    };

    const onSubmit = (data) => {
        setMobileError("");
        setApiLoading(true);
        let modelId = modelData.find(model => model.uuid == productData.model_uuid)?.id
        let valid = true;
        let mobile = mobileNumber != 0 ? mobileNumber.substring(1) : "";
        if (!mobilePrefix || !mobile || mobile.length == 0) {
            valid = false;
            setMobileError("Mobile Number is required")
        }
        if (valid) {
            let formData = {
                name: data.name,
                email: data.email,
                brand_id: productData.brand_info.id,
                model_id: modelId,
                year: productData.manufactured_year,
                transmission: productData.transmission,
                mobile_number: mobile,
                mobile_number_country: typeof parseInt(mobilePrefix) == "number"
                    ? (mobileInputRef.current?.state?.selectedCountry?.iso2 == undefined
                        ? "my"
                        : mobileInputRef.current?.state?.selectedCountry?.iso2)
                    : mobilePrefix,
                enquiry_type: 0,
                website_url: window.location.href,
                creator_id: productData.creator_info.id
            }
            cleanFormData(formData);
            submitEnquiry(formData);
            // console.log(formData);
        }
    }

    const submitEnquiry = (data) => {
        GENERAL.sendSellCarEnquiry(data)
            .then(res => {
                toast.success("Request submitted successfully. Please wait for our agent to contact you.", { autoClose: 3000 });
                setApiLoading(false);
                setSubmitted(true);
                props.closeModal();
            })
            .catch(err => {
                setApiLoading(false);
                showErrMsg(err)
            });
    }

    const showErrMsg = (err) => {
        setApiLoading(false);
        if (err.http_code == 422 && err.data.errors) {
            addServerErrors(err.data.errors);
        } else {
            toast.error("Something wrong.Please try again later.");
        }
    };

    const addServerErrors = (errors) => {
        let validationErrors = {}
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            if (key == 'mobile_number' || key == 'phone') {
                setMobileError(errors[errorKey].message)
            }
            else {
                return Object.keys(validationErrors).forEach((key) => {
                    setError(key, {
                        type: "server",
                        message: errors[errorKey].message
                    });
                });

            }
        }
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <div className="col-md-12">
                        <p><strong>Car Info</strong></p>
                        <div>Car Brand : <strong>{productData.brand_info?.name}</strong></div>
                        <div>Car Model : <strong>{modelData.find(model => model.uuid == productData.model_uuid)?.name}</strong></div>
                        <div>Category : <strong>{productData.product_category_info?.name}</strong></div>
                        <div>Fuel Type : <strong>{(productData.fuel_type)}</strong></div>
                        <div>Manufactured Year : <strong>{productData.manufactured_year}</strong></div>
                        <div>Transmission : <strong>{productData.transmission == "1" ? "Automatic" : "Manual"}</strong></div>
                        <hr style={{ borderTop: "3px dashed #bbb" }}></hr>

                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="form-group">
                            <label htmlFor="booking-name">Name <span className="text-danger">*</span></label>
                            <input
                                className={`form-control ${errors.name == null ? '' : 'is-invalid'}`}
                                id="name" name="name" type="text" placeholder="Enter name" onChange={(e) => setName(e.target.value)}
                                ref={register({
                                    required: { value: true, message: "Name is required" },
                                    maxLength: { value: 255, message: "Name should not exceed 255 characters" },
                                })}
                                defaultValue={token && login_name ? login_name : ""}
                            />
                            {errors.name == null ? '' : <div className="invalid-feedback">{errors.name.message}</div>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="booking-name">Email</label>
                            <input
                                className={`form-control ${errors.email == null ? '' : 'is-invalid'}`}
                                id="email" name="email" type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}
                                ref={register({
                                    required: { value: false, message: "Email is required" },
                                    maxLength: { value: 255, message: "Email should not exceed 255 characters" },
                                    minLength: { value: 2, message: "Email should more than 2 characters" },
                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email entered is invalid" }
                                })}
                                defaultValue={token && login_email ? login_email : ""}
                            />
                            {errors.email == null ? '' : <div className="invalid-feedback">{errors.email.message}</div>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="booking-mobile">Mobile Number <span className="text-danger">*</span></label>
                            <PhoneInput
                                inputClass={"form-control"}
                                value={(mobileNumber ? mobileNumber : "")}
                                onlyCountries={["my", "sg"]}
                                country={mobileNumber ? "" : "my"}
                                onChange={(value, country) => handleMobileChange(value, country)}
                                autoFormat={false}
                                inputProps={{ required: true }}
                                ref={mobileInputRef}
                                // preferredCountries={["my", "sg"]}
                                countryCodeEditable={false}
                            />
                            {mobileError && <div style={{ fontSize: "12px" }} className="error text-danger"><span>{mobileError}</span></div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    {apiLoading ? (
                        <button className="btn btn-primary mt-2 mt-md-3 mt-lg-4" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    ) : (
                        <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" id={"submit in booking form"} style={{ width: '50%' }} onClick={handleSubmit(onSubmit)}>
                            <span className='d-none d-sm-block'>{submitted == false ? 'Book Test Drive' : 'Submitted Buy'}</span>
                            <span className='d-block d-sm-none'>{submitted == false ? 'Test Drive' : 'Submitted Buy'}</span>
                        </button>)}
                    <button type="button" className="btn btn-success mt-2 mt-md-3 mt-lg-4" id={"booking whatsapp"} style={{ width: '50%' }} disabled={whatsAppDisabled} style={{ cursor: whatsAppDisabled == true ? 'not-allowed' : 'pointer' }}>
                        <a
                            type="button"
                            target="_blank"
                            href={"https://api.whatsapp.com/send?phone=" + (bookingNo ? bookingNo : theme.whatsapp.buy) + "/&text=" + getPresetMessage()}
                        // href={"https://api.whatsapp.com/send?phone=" + theme.whatsapp.buy + "/&text=" + getPresetMessage()}
                        >

                            <span className='d-none d-sm-block' id={"booking whatsapp"}>WhatsApp for enquiry</span>
                            <span className='d-block d-sm-none' id={"booking whatsapp"}>WhatsApp</span>

                        </a>

                    </button>
                </div>

            </form>
        </React.Fragment>
    )

}