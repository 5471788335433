// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';
import PageHeader from '../shared/PageHeader';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <React.Fragment>
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <PageHeader />

            <div className="block">
                <div className="container">
                    <div className="about-us__body">
                        <h1 className="about-us__title">About Us</h1>
                        <div className="typography">
                            <p align="justify">
                                <strong>We Are </strong>
                                <strong />
                            </p>
                            <p align="justify">
                                Duitcar is latest online platform for End-user to sell used car & browse used vehicle .
                                We have in used car business for more than 20 years .
                            </p>
                            <p align="justify">
                                <strong>What we offer </strong>
                                <strong />
                            </p>
                            <p align="justify">
                                Our platform able let customer to customize their desire vehicle from
                                body colour , rim design , bodykit add on and more.
                                We offer higher trade-in value for your used vehicle .
                                We offer high quality of used car vehicle with more than hundred of unit to browse.
                                We offer arrangement of HP Loan , Insurance Renewal or Cash Purchase
                            </p>
                            <p align="justify">
                                <strong>Our objective </strong>
                                <strong />
                            </p>
                            <p align="justify">
                                <div>To cater all range of customer from low to high end with quality used vehicle .</div>
                                <div>To help customer to fetch higher trade in value of the used vehicle .</div>
                                <div>To simplify the process selling used vehicle as 1 2 3 .</div>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
        // <div className="block about-us">
        //     <Helmet>
        //         <title>{`About Us — ${theme.name}`}</title>
        //     </Helmet>
        //     <div className="about-us__image" />
        //     <div className="container">
        //         <div className="row justify-content-center">
        //             <div className="col-12 col-xl-10">
        //                 <div className="about-us__body">
        //                     <h1 className="about-us__title">About Us</h1>
        //                     <div className="about-us__text typography">
        //                         <h4>We Are</h4>
        //                         <p align="justify">
        //                             Duitcar is latest online platform for End-user to sell used car & browse used vehicle .
        //                             We have in used car business for more than 20 years .
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default SitePageAboutUs;
