import * as HELPER from "../helper/general-helper";
import authHeader from "../helper/auth-header";
const axios = require("axios");

export async function getBrandList(params) {

  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/brand" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getModelListByBrand(id, params) {
  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/car-model/" + id + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function sendSellCarEnquiry(data) {
  let header = await authHeader();
  header["Content-Type"]= "multipart/form-data"
  header["Access-Control-Allow-Origin"]= "*"

  var formData = HELPER.appendFormData(data);

  if (data.images) {
    data.images.forEach((image, index) => {
      formData.append("images[" + index + "][media]", image.file);
      formData.append("images[" + index + "][is_banner]", index == 0 ? 1 : 0);
    });
  }

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_HOST + "/"
        + process.env.REACT_APP_API_PREFIX +
        "/car-enquiry", formData,
        {
          timeout: 60000,
          headers: header
        })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getPopularBrandList(params) {
  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/brand" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getAllModelList(params) {
  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/car-model" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}

export async function getSystemSetting(params) {
  let querystring = HELPER.paramBuilder(params);

  let url =
    process.env.REACT_APP_HOST +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/system-setting" + querystring;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        timeout: 60000,
        //   headers: authHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({
              http_code: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error.message);
        }
      })
      .finally();
  });
}