// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getNoImg } from '../../services/utils';

function CarCard(props) {
  const { type, layout } = props;
  const cardClasses = classNames(
    'car-card',
    {
      "store-card--layout--grid store-card--size--sm": layout === "grid-sm",
      "store-card--layout--grid store-card--size--nl": layout === "grid-nl",
      "store-card--layout--grid store-card--size--lg": layout === "grid-lg",
      "store-card--layout--list": layout === "list",
      "store-card--layout--horizontal": layout === "horizontal",
    },
  );

  let image;
  image = (
    <div className="car-card__image store-image">
      <Link to={{
        pathname: `/buy-car/${type.slug}`,
        state: { brand: type.name }
      }}>
        <img className="car-image__img" src={type.image ? type.image.url : getNoImg()} alt={type.name} onError={(e) => (e.target.src = getNoImg())} />
      </Link>
    </div>
  );

  return (
    <div className={cardClasses} style={{ backgroundColor: "#ffffff" }}>
      {image}
      <div className="car-card__info">
        <div className="car-card__name">
          {/* <Link to="/buy-car"> */}
          <Link to={{
            pathname: `/buy-car/${type.slug}`,
            state: { brand: type.name }
          }}>
            {type.name}</Link>
        </div>
      </div>
    </div>
  );
}

CarCard.propTypes = {
  /**
   * post data object
   */
  post: PropTypes.object,
  /**
   * post card layout
   * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
   */
  layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default CarCard;
