// react
import React from 'react';

function ProductTabDescription(props) {
    return (
        <div className="typography">
            <div className="product-desc" dangerouslySetInnerHTML={{ __html: props.data.description }} />
        </div>
    );
}

export default ProductTabDescription;
