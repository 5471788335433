// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
// import BlockHeader from '../shared/BlockHeader';
// import ProductCard from '../shared/ProductCard';
import StroykaSlick from '../shared/StroykaSlick';

import { ArrowPointToLeft, ArrowPointToRight } from "../../svg";
import { Link } from 'react-router-dom';
import { getNoImg } from '../../services/utils';

const slickSettings = {
    'grid-5': {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockCarBrandCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentSlide: 0,
        }
    }
    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setCurrentSlide(index) {
        this.setState({
            currentSlide: index,
        });
    }

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    brandColumns() {
        const columns = [];
        const { rows, carBrandImgList } = this.props;

        let { brand } = this.props;
        if (rows > 0) {
            brand = carBrandImgList.slice(0, carBrandImgList.length);

            while (brand.length > 0) {
                columns.push(brand.splice(0, rows));
            }
        }

        return columns;
    }

    render() {
        const { layout, withSidebar, loading } = this.props;
        const columns = this.brandColumns().map((column, index) => {
            const brand = column.map((brand) => (
                <Link to={{
                    pathname: brand.name == "All Brand" ? '/all-brand' : `/brand/${brand.slug}`,
                    state: { brand: brand.name }
                }}>
                    <div key={brand.id} className="block-products-carousel__cell">
                        <div className={brand.name == "All Brand" ? "category-card__body brand-view-more-card" : "category-card__body"}>
                            <div className={brand.name == "All Brand" ? 'category-card__image brand-view-more-image' : "category-card__image"}>

                                {brand.name != "All Brand" ?
                                    <img src={brand.image ? brand.image.url : getNoImg()} alt={brand.name} /> : null}
                                <div className={brand.name == "All Brand" ? "brand-view-more-text" : "category-card__name"}>{brand.name}</div>

                            </div>
                        </div>
                    </div>
                </Link>
            ));

            return (
                <div key={index} className="block-products-carousel__column">
                    {brand}
                </div>
            );
        });

        const blockClasses = classNames('block block-products-carousel', {
            'block-products-carousel--loading': loading,
            'block-products-carousel--has-items': columns.length > 0,
        });
        const containerClasses = classNames({
            container: !withSidebar,
        });

        return (
            <div className={blockClasses} data-layout={layout}>
                <div className={containerClasses}>

                    <div className="block-products-carousel__slider">
                        <div className="block-products-carousel__preloader" />

                        <div className="slider-arrow-circle left cuisine" style={{ top: "30%" }} onClick={this.handlePrevClick}>
                            <ArrowPointToLeft />
                        </div>

                        <StroykaSlick
                            ref={this.setSlickRef}
                            {...slickSettings[layout]}
                        >
                            {columns}
                        </StroykaSlick>
                        <div className="slider-arrow-circle right cuisine" style={{ top: "30%" }} onClick={this.handleNextClick}>
                            <ArrowPointToRight />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockCarBrandCarousel.propTypes = {
    // title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};

BlockCarBrandCarousel.defaultProps = {
    layout: 'grid-5',
    rows: 1,
    brand: [],
    groups: [],
    withSidebar: false,
    loading: false,
    // onGroupClick: undefined,
};
