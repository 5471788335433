import { UPDATE_USER,UPDATE_TOKEN,UPDATE_NOTIFICATION } from './userActionTypes';

// eslint-disable-next-line import/prefer-default-export
export function updateUser(profile) {
    return {
        type: UPDATE_USER,
        profile,
    };
}

export function updateToken(token) {
    return {
        type: UPDATE_TOKEN,
        token,
    };
}

export function updateNotification(notification) {
    return {
        type: UPDATE_NOTIFICATION,
        notification,
    };
}