// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { CalenderSvg, SpeedMeterSvg, TransmissionSvg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { allEqual, getDefaultAvatar, getNoImg, url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;

    // if (product.badges.includes('sale')) {
    //     badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    // }
    // if (product.badges.includes('hot')) {
    //     badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    // }
    // if (product.badges.includes('new')) {
    //     badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    // }

    // badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    // if (product.images && product.images.length > 0) {
    image = (
        <div className="product-card__image product-image">
            <Link to={url.product(product)} className="product-image__body">
                <img
                    className="product-image__img"
                    src={
                        product.images?.[0]
                            ? product.images?.[0].url
                            : getNoImg()
                    }
                    alt=""
                    onError={(e) => (e.target.src = getNoImg())}
                />
            </Link>
        </div>
    );
    // }

    price = (
        <div className="product-card__prices">
            {!product.is_sales && allEqual(product.price) ? (
                <Currency value={product.price} />
            ) : product.is_sales && allEqual(product.sales_price) ? (
                <>
                    <span className="product__new-price">
                        <Currency value={product.sales_price} />
                    </span>
                    {!allEqual([product.sales_price, product.price]) ? (
                        <span className="product__old-price" style={{ fontSize: "inherit" }}>
                            <Currency value={product.price} />
                        </span>
                    ) : null}
                </>
            ) : product.is_sales ? (
                <>
                    <Currency value={product.sales_price[0]} />{" "}
                    {product.sales_price[product.sales_price.length - 1] ? " - " : null}{" "}
                    {product.sales_price[product.sales_price.length - 1] ? (
                        <Currency value={product.sales_price[product.sales_price.length - 1]} />
                    ) : null}
                </>
            ) : (
                <>
                    <Currency value={product.price[0]} />{" "}
                    {product.price[product.price.length - 1] ? " - " : null}{" "}
                    {product.price[product.price.length - 1] ? (
                        <Currency value={product.price[product.price.length - 1]} />
                    ) : null}
                </>
            )}
        </div>
    );

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes.filter((x) => x.featured).map((attribute, index) => (
                    <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
                ))}
            </ul>
        );
    }

    return (
        <div className={containerClasses}>
            {/* {badges} */}
            {image}
            <div className="product-card__info">
                {product.brand_info ? (
                    <Link to={url.brand(product)} className="brand_name">
                        {product.brand_info.name}
                    </Link>
                ) : null}
                <div className="product-card__name">
                    <Link to={url.product(product)}>{product.id ? `${product.id})` : ""} {product.name}</Link>
                </div>
            </div>
            <div className="product-card__actions">
                {price}
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
