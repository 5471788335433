import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getAccessToken, logout } from '../api/auth';

export default function cleanFormData(obj) {
    for (var propName in obj) { 
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
          delete obj[propName];
        }
    }
    return obj;
}

/* Append data into FormData instance */
export function appendFormData(data) {
    var formData = new FormData();
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
}

export function paramBuilder(rawParam) {
  let param = ""
  for(var key in rawParam) {
    if(rawParam[key]) {
      let symbol = param == '' ? '?' : '&';
      param = param + symbol + key + '=' + rawParam[key];
    }
  }
  return param;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export function checkUnauthorized(err, history) {
  if (err.http_code == 401 || err.http_code == 403) {
      for (const errKey in err.data?.errors) {
          if (errKey.split('.')[0] == 'token') {
              if (getAccessToken()) {
                  toast.error("Sorry, your login session expired. Please try again.");
                  if (history) logout(history);
                  else logout();
              }
              break;
          }
      }
      return true;
  } else {
      return false;
  }
}