// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// application
import { mobileUserMenuClose } from "../../store/mobile-menu";
import { ArrowPointToLeft } from "../../svg";
import * as AUTH from "../../api/auth";
import { toast } from "react-toastify";
import currencies from "../../data/shopCurrencies";
import mobileMenuLinks from "../../data/mobileMenu";
import { getDefaultAvatar } from "../../services/utils";
import accountMenuList from "../../data/accountMenuList";
const defaultAvatar = getDefaultAvatar();

function MobileUserMenu(props) {
  const { user, mobileMenuState, closeMobileUserMenu } = props;
  const history = useHistory();

  const classes = classNames("mobile-usermenu", {
    "mobile-usermenu--open": mobileMenuState.userMenuOpen,
  });

  function handleLogout() {
    closeMobileUserMenu();
    toast.info("Logout Successful", {  autoClose: 2000 });
    AUTH.logout(history);
  }

  return (
    <div className={classes}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className="mobile-usermenu__backdrop" onClick={closeMobileUserMenu} />
      <div className="mobile-usermenu__body">
        <div className="mobile-usermenu__header">
          <div className="col-12 pt-3">
            <div className="back-arrow" onClick={closeMobileUserMenu}>
              <ArrowPointToLeft />
            </div>
          </div>
          <div className="mobile-usermenu__user-avatar">
            <img src={user && user.image ? user.image : defaultAvatar} alt="user-avatar" onError={(e) => (e.target.src = getDefaultAvatar())} />
            <div className="name">{user ? user.name : ""}</div>
          </div>
        </div>
        <div>
          <ul className="user-menu-list">
            {accountMenuList.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.url} onClick={closeMobileUserMenu}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
            <hr />
            <li>
              <Link to="/login" onClick={handleLogout}>
                Log Out
              </Link>
            </li>
          </ul>
        </div>

        {/* <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div> */}
        {/* <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mobileMenuState: state.mobileMenu,
  user: state.user.profile,
});

const mapDispatchToProps = {
  closeMobileUserMenu: mobileUserMenuClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileUserMenu);
