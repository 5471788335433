// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import shopApi from '../../api/shop';
import { getDefaultAvatar, noImgSmall, url } from '../../services/utils';

// blocks
import BlockLoader from '../blocks/BlockLoader';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
import theme from '../../data/theme';

import * as PRODUCT from '../../api/product'
import * as GENERAL from '../../api/general'
function ShopPageProduct(props) {
    const { productSlug, layout, sidebarPosition } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [productDetail, setProductDetail] = useState([]); // product specification tab
    // const [relatedProducts, setRelatedProducts] = useState([]);
    // const [latestProducts, setLatestProducts] = useState([]);

    // Load product.
    useEffect(() => {
        let canceled = false;

        setIsLoading(true);

        PRODUCT.getProductDetails(productSlug)
            .then(res => {
                setProduct(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            })

        return () => {
            canceled = true;
        };
    }, [productSlug, setIsLoading]);

    useEffect(() => {
        if (product != null) {
            let a = []; let b = [];
            a.push(
                {
                    key: 'Brand',
                    value: product.brand_info?.name
                },
                {
                    key: 'Status',
                    value: product.status?.name
                },
                {
                    key: 'Category',
                    value: product.product_category_info?.name
                }
            )
            if (product.specification != null) {
                b = [...a, ...product.specification];
            } else {
                b = [...a]
            }
            setProductDetail(b)
        }
    }, [product])

    // // Load latest products.
    // useEffect(() => {
    //     let canceled = false;

    //     if (layout !== 'sidebar') {
    //         setLatestProducts([]);
    //     } else {
    //         shopApi.getLatestProducts({ limit: 5 }).then((result) => {
    //             if (canceled) {
    //                 return;
    //             }

    //             setLatestProducts(result);
    //         });
    //     }

    //     return () => {
    //         canceled = true;
    //     };
    // }, [layout]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
    ];

    breadcrumb.push({ title: product.brand_info?.name ? product.brand_info.name : "Brand", url: url.brand(product) });
    breadcrumb.push({ title: product.name, url: url.product(product) });

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        {/* <WidgetProducts title="Latest Products" products={latestProducts} /> */}
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs withSidebar />
                        </div>

                        {/* {relatedProducts.length > 0 && (
                            <BlockProductsCarousel
                                title="Related Products"
                                layout="grid-4-sm"
                                products={relatedProducts}
                                withSidebar
                            />
                        )} */}
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} />
                        <ProductTabs data={productDetail} description={product} />
                    </div>
                </div>

                {/* {relatedProducts.length > 0 && (
                    <BlockProductsCarousel
                        title="Related Products"
                        layout="grid-5"
                        products={relatedProducts}
                    />
                )} */}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
                <meta name="description" content="Duitcar is latest online platform for End-user to sell used car & browse used vehicle." />
                <meta name="og:description" content={product.short_description} />
                <meta property="og:title" content={`${product.name} — ${theme.name}`} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={product.short_description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={product.images && product.images[0] ? product.images[0].responsive_url[product.images[0].responsive_url.length - 1] : noImgSmall()} />,

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta name="twitter:title" content={`${product.name} — ${theme.name}`} />
                <meta name="twitter:description" content={product.short_description} />
                <meta property="twitter:image" content={product.images && product.images[0] ? product.images[0].responsive_url[product.images[0].responsive_url.length - 1] : noImgSmall()} />
                {/* <meta key="img" property="og:image" content={productData && productData.images ? productData.images[0].responsive_url[productData.images[0].responsive_url.length - 1] : getDefaultAvatar()} />, */}
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPageProduct;
