import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN, MOBILE_USERMENU_OPEN, MOBILE_USERMENU_CLOSE } from "./mobileMenuActionTypes";

export function mobileMenuOpen() {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose() {
    return { type: MOBILE_MENU_CLOSE };
}

export function mobileUserMenuOpen() {
    return { type: MOBILE_USERMENU_OPEN };
}

export function mobileUserMenuClose() {
    return { type: MOBILE_USERMENU_CLOSE };
}
